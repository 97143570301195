import React from "react";
import Hero from "./Hero";
import Details from "./Details";

export default function Intro() {
  return (
    <div>
      <Hero />

      <Details />
    </div>
  );
}
