import React from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";

export default function DrawLineConfirmed() {
  const { t } = useTranslation("global");

  const {
    coordinates,
    setcoordinates,
    featureList,
    setfeatureList,
    lat,
    setdrawnow,
    setdrawMiss,
    setdrawMissSecond,
    setanotherMiss,
    setdrawConfirmed,
    setdrawLine,
    setshowMsgHint,
    setshowHintbox,
    setdisplayMsg,
    setdisplayMsgh1,
    enableContinue,
  } = useMapState();

  const showDrawHouse = document.querySelectorAll(".showDrawHouse");
  const nostarttwo = document.querySelectorAll(".nostarttwo");
  const drawConfirmedyes = document.querySelectorAll(".drawConfirmedyes");
  const starttwo = document.querySelectorAll(".starttwo");
  const nodrawLine = document.querySelectorAll(".nodrawLine");
  const showdrawHouseSecond = document.querySelectorAll(".showdrawHouseSecond");
  const nostartthree = document.querySelectorAll(".nostartthree");
  const yesnodrawConfirmed = document.querySelectorAll(".yesnodrawConfirmed");

  const handleAddLineToList = (msg) => {
    setdrawnow(false);
    setshowHintbox(false);

    const features = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
      properties: {
        id: "Voorgevelrooilijn",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setcoordinates("");
    }

    setanotherMiss(false);
    setdrawConfirmed(true);
    setshowHintbox(true);
    setshowMsgHint(true);
    setdisplayMsg(t("flow.106"));
    setdisplayMsgh1(t("flow.6"));

    if (msg === "fst") {
      drawConfirmedyes.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      yesnodrawConfirmed.forEach((item) => {
        item.classList.remove("showitem");
        item.classList.add("hideitem");
      });
      showDrawHouse.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      nostarttwo.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      starttwo.forEach((item) => {
        item.classList.remove("showitem");
        item.classList.add("hideitem");
      });
    } else {
      nodrawLine.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      showdrawHouseSecond.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      nostartthree.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
    }

    setdrawLine(false);
    setdrawMiss(false);
    setdrawMissSecond(false);
  };

  return (
    <div className="allitems hideitem">
      <div className="start">
        <div className="yesnodrawConfirmed">
          <div className="text-box text-primaryBlue text-lg mb-4">
            {t("flow.2")}
          </div>

          <button
            className="primary-btn"
            disabled={!enableContinue}
            onClick={() => handleAddLineToList("fst")}
          >
            {t("button.3")}
          </button>
        </div>
      </div>
    </div>
  );
}
