import { useTranslation } from "react-i18next";
import Steps from "./Steps";
import { IoIosWarning } from "react-icons/io";
import MustRead from "./MustRead";
import { FaArrowRight } from "react-icons/fa";
import AnimatedTitle from "../../../Common/AnimatedTitle";
import RevealingFromBottom from "../../../Common/RevealingFromBottom";
import ScaleOut from "../../../Common/ScaleOut";
import { useScrollAnimate } from "../../../../hooks/useScrollAnimate";
import { motion } from "framer-motion";

export default function WarningsBox({ stepsComplete, setStepsComplete, read }) {
  const { t } = useTranslation("global");
  const { ref, controls } = useScrollAnimate();

  const numSteps = 8;

  const warnings = t("warningsSection.content", { returnObjects: true });

  const handleSetStep = (num) => {
    if (
      (stepsComplete === 0 && num === -1) ||
      (stepsComplete === numSteps && num === 1)
    ) {
      return;
    }

    setStepsComplete((pv) => pv + num);
  };

  return (
    <div ref={ref} id="warnings" className="mb-40 relative z-10">
      <div className="text-center mb-20">
        <AnimatedTitle
          duration={0.6}
          className="text-4xl font-semibold text-primaryBlue"
        >
          {t("warningsSection.title")}
        </AnimatedTitle>

        <RevealingFromBottom
          duration={0.6}
          extraDelay={0.2}
          className="w-[40%] mx-auto"
        >
          {t("warningsSection.subtitle")}
        </RevealingFromBottom>
      </div>

      <div className="max-w-4xl flex flex-col mx-auto relative z-10">
        <Steps numSteps={numSteps} stepsComplete={stepsComplete} />

        <div className="p-6 space-y-4 my-6 h-48 font-medium border-gray-200 rounded-lg">
          {stepsComplete === 8
            ? warnings
                .at(7)
                .split("|")
                .map((line, index) => (
                  <RevealingFromBottom duration={0.6} extraDelay={index / 20}>
                    {line}
                  </RevealingFromBottom>
                ))
            : warnings
                .at(stepsComplete)
                .split("|")
                .map((line, index) => (
                  <RevealingFromBottom duration={0.6} extraDelay={index / 20}>
                    {line}
                  </RevealingFromBottom>
                ))}
        </div>

        <div className="flex items-center justify-end gap-2">
          <ScaleOut duration={0.6} delay={0.5}>
            <button
              className="flex justify-end items-center gap-x-3 hover:gap-x-6 w-[120px] active:scale-95 shadow-jubilation hover:brightness-110 hover:shadow-none transition-all text-primaryBlue border-2 border-primaryBlue px-4 py-2 rounded-xl"
              onClick={() => handleSetStep(-1)}
            >
              <FaArrowRight className="rotate-180" />
              <span> {t("warningsSection.prevBtn")}</span>
            </button>
          </ScaleOut>

          <ScaleOut duration={0.6} delay={0.7}>
            <button
              onClick={() => handleSetStep(1)}
              disabled={stepsComplete === 8}
              className="flex items-center gap-x-3 disabled:bg-primaryBlue/70 disabled:border-primaryBlue/5 hover:gap-x-6 w-[140px] active:scale-95 shadow-jubilation hover:brightness-110 hover:shadow-none transition-all bg-primaryBlue border-2 border-primaryBlue text-white px-4 py-2 rounded-xl"
            >
              <span>
                {stepsComplete === 7 || stepsComplete === 8
                  ? t("warningsSection.done")
                  : t("warningsSection.nextBtn")}
              </span>{" "}
              <FaArrowRight />{" "}
            </button>
          </ScaleOut>
        </div>
      </div>

      <motion.div
        variants={{
          hidden: { opacity: 0, rotate: -45, x: -20, y: -20 },
          visible: { opacity: 1, rotate: 45, x: 0, y: 0 },
        }}
        initial="hidden"
        animate={controls}
        transition={{
          duration: 0.5,
          delay: 0.6,
        }}
        className="absolute -top-10 right-0 lg:right-20 rotate-45 z-10"
      >
        <div className="bg-secondaryBlue border-[10px] lg:border-[15px] rounded-full p-4 flex items-center justify-center aspect-square">
          <IoIosWarning className="text-[80px] lg:text-[150px] -mt-5 text-secondaryGray" />
        </div>
      </motion.div>

      <img
        className="h-[400px] aspect-auto absolute -bottom-20 -right-20 opacity-10 blur-3xl"
        src={`${process.env.PUBLIC_URL}/warning/warningsShape.svg`}
        alt=""
      />

      <MustRead read={read} />
    </div>
  );
}
