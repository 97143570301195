import React from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";

export default function AskForNewConstruction() {
  const { t } = useTranslation("global");

  const {
    setResidential,
    setMain,
    setpay,
    setdrawMiss,
    setanotherMiss,
    setisRes,
    setmainBuild,
    setcontinueFlow,
  } = useMapState();

  const anotherMissnoaskMissFirst = document.querySelectorAll(
    ".anotherMissnoaskMissFirst"
  );

  const undo = document.getElementById("undo");

  const handleContinueDrawMissing = (msg, sit) => {
    setcontinueFlow(false);
    anotherMissnoaskMissFirst.forEach((item) => {
      item.classList.remove("showitem");
      item.classList.add("hideitem");
    });
    if (sit === "yes") {
      setmainBuild(true);
      setMain("yes");
      setisRes(true);
      setResidential("yes");
    }
    if (msg === "yes") {
      setdrawMiss(true);
      setanotherMiss(false);
    } else if (msg === "no") {
      undo?.classList.add("hideitem");
      undo?.classList.remove("showitem");
      setanotherMiss(false);
      setpay(true);
    }
  };

  return (
    <div className="anotherMissnoaskMissFirst">
      <div className="text-box text-center text-lg text-primaryBlue">
        <p>{t("flow.7")}</p>
      </div>
      <div className="flex gap-x-4 mt-4">
        <button
          className="primary-btn !py-1.5"
          onClick={() => handleContinueDrawMissing("yes", "no")}
        >
          {t("yesno.1")}
        </button>
        <button
          className="primary-btn !py-1.5 border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
          id="handleContinueDrawMissingSecond"
        >
          {t("yesno.2")}
        </button>
      </div>
    </div>
  );
}
