import { useTranslation } from "react-i18next";
import SingleCard from "./SingleCard";

export default function Details() {
  const { t } = useTranslation("global");

  const data = t("solutions", { returnObjects: true });

  return (
    <div className="grid grid-cols-2 mt-40 relative px-5 gap-5 lg:px-14 lg:gap-7 xl:px-44 xl:gap-10">
      {data.map((single, idx) => (
        <SingleCard single={single} idx={idx} />
      ))}

      <div
        className="absolute top-0 transform-gpu overflow-hidden left-0 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="relative aspect-[1155/678] w-[36.125rem] max-w-none rotate-[30deg] bg-gradient-to-tr from-secondaryBlue to-secondaryBlue/50 opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
    </div>
  );
}
