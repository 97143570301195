import { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import SingleCard from "./SingleCard";

export default function HowItWorks() {
  const { t } = useTranslation("global");
  const [showImage, setShowImage] = useState(false);

  const solutions = t("howItWorks.content", { returnObjects: true });

  const [open, setOpen] = useState(0);

  return (
    <>
      <section id="howworks" className="px-5 lg:px-14 xl:px-28 pt-40">
        <div className="w-full mx-auto grid gap-8 grid-cols-1 lg:grid-cols-[1fr_400px]">
          <div>
            <h3 className="text-4xl text-secondaryOrange font-bold mb-8">
              {t("howItWorks.header")}
            </h3>

            <div className="flex flex-col gap-4">
              {solutions.map((q, idx) => {
                return (
                  <SingleCard
                    {...q}
                    key={idx}
                    open={open}
                    setOpen={setOpen}
                    index={idx}
                  />
                );
              })}
            </div>
          </div>

          <div className="relative group bg-red-400 h-fit my-auto">
            <motion.img
              className="h-full w-full object-contain"
              src={`${process.env.PUBLIC_URL}/howItWorks/howworksnew.png`}
              alt="howworks"
            />

            <div
              onClick={() => setShowImage(true)}
              className="absolute cursor-pointer opacity-0 group-hover:opacity-100 transition-all duration-500 top-0 left-0 w-full h-full bg-white/50 backdrop-blur-sm flex items-center justify-center"
            >
              <p>Open</p>
            </div>
          </div>
        </div>
      </section>

      {showImage && (
        <div className="bg-gray-500/50 backdrop-blur-sm fixed top-0 left-0 w-screen h-screen z-50">
          <div className="relative w-screen h-screen">
            <div className="absolute flex flex-col items-center top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-50 bg-white rounded-2xl shadow-intense p-6 text-white w-[60%]">
              <motion.img
                className="h-full w-full object-contain"
                src={`${process.env.PUBLIC_URL}/howItWorks/howworksnew.png`}
                alt="howworks"
              />

              <button
                className="absolute top-2 right-2 p-2 text-white bg-red-600 rounded-xl hover:scale-105 transition-all duration-500 cursor-pointer"
                onClick={() => setShowImage(false)}
              >
                sluiten
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
