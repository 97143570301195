import React from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../helpers/mapState";
import { MdHelpOutline } from "react-icons/md";
import ReactHtmlParser from "react-html-parser";

export default function HintBox() {
  const { t } = useTranslation("global");

  const { showHintbox, showMsgHint, displayMsg, displayMsgh1, setshowMsgHint } =
    useMapState();

  const displayMsgFunction = () => {
    setshowMsgHint(!showMsgHint);
  };

  return (
    <>
      {showHintbox && !showMsgHint && (
        <button
          className="absolute top-4 left-4 primary-btn flex items-center !w-fit px-4 gap-x-2 capitalize z-10"
          onClick={displayMsgFunction}
        >
          <MdHelpOutline />
          <span>{showMsgHint ? t("flowtexts.2") : t("flowtexts.1")}</span>
        </button>
      )}

      {showHintbox && showMsgHint && (
        <div className="fixed bg-gray-300/50 top-0 left-0 w-screen h-screen z-[40]">
          <div className="relative w-screen h-screen flex items-center justify-center">
            <div className="shadow-jubilation rounded-xl overflow-hidden bg-white max-w-3xl">
              <div className="px-8 py-6">
                <h3 className="text-xl font-semibold mb-3">{displayMsgh1}</h3>

                <div className="pl-8">
                  {displayMsg.includes("<div>") && ReactHtmlParser(displayMsg)}
                </div>

                <ul className="list-disc pl-8">
                  {!displayMsg.includes("<div>") &&
                    (displayMsg.split("/n").length === 1
                      ? displayMsg
                      : displayMsg
                          .split("/n")
                          .filter((item) => item.trim().length > 0)
                          .map((msg) => <li>{msg}</li>))}
                </ul>

                {/* <span>{t("flowtexts.3")}</span> */}
                <div className="flex justify-center">
                  <button
                    onClick={displayMsgFunction}
                    className="primary-btn mt-5 !w-[300px]"
                  >
                    {t("flowtexts.2")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
