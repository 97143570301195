import { saveAs } from "file-saver";
import useMapState from "../helpers/mapState";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { MdFileDownload } from "react-icons/md";

export default function CheckBoxes({ sendReq }) {
  const { t } = useTranslation("global");

  const {
    checkbox1,
    setCheckbox1,
    checkbox2,
    setCheckbox2,
    checkbox3,
    setCheckbox3,
    name,
  } = useMapState();

  const handleDownloadPdf = () => {
    let filename = "Algemene-voorwaarden.pdf";
    const file = `${process.env.PUBLIC_URL}/downloads/Algemene_voorwaarden_Bouwruimte.nl.pdf`;

    fetch(file)
      .then(function (response) {
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob());
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then((blob) => saveAs(blob, filename))
      .catch((e) => console.error(e));
  };

  const allCheckboxesChecked = checkbox1 && checkbox2 && checkbox3;

  const checkout = async () => {
    sendReq();

    window.location.replace(
      "https://bouwruimte.nl/backend-php/payCheckoutService.php"
    );

    // window.location.replace(
    //   "http://localhost/mhmdali/domains/bouwruimte.nl/public_html/backend-php/payCheckoutService.php"
    // );
  };

  return (
    <>
      {name !== "" && (
        <div className="flex flex-col justify-center pb-20 w-[700px]">
          <div className="shadow-jubilation rounded-xl max-w-2xl py-7 px-7 space-y-4">
            <CheckBoxComp
              id="verifyread1"
              onChange={() => setCheckbox1(!checkbox1)}
              label={
                <p className="space-x-2">
                  <span>Ik ga akkoord met de</span>
                  <span
                    onClick={handleDownloadPdf}
                    className="bg-gray-500 font-semibold text-white px-2 py-1 rounded-md cursor-pointer hover:brightness-150 transition-all"
                  >
                    Algemene voorwaarden
                  </span>
                  <span>van Bouwruimte.nl</span>
                </p>
              }
            />

            <CheckBoxComp
              id="verifyread2"
              onChange={() => setCheckbox2(!checkbox2)}
              label={t("aftread.7")}
            />

            <CheckBoxComp
              id="verifyread3"
              onChange={() => setCheckbox3(!checkbox3)}
              label={t("aftread.8")}
            />

            {/* <button
              onClick={handleDownloadPdf}
              className="text-sm text-gray-500 hover:text-gray-800 font-semibold flex items-center gap-x-2 !mt-6 !mb-3 mx-auto hover:scale-105 transition-all"
            >
              <span>Algemene voorwaarden downloaden</span>
              <MdFileDownload className="mt-1" />
            </button> */}

            <div className="w-[50%] mx-auto mt-8">
              <button
                disabled={!allCheckboxesChecked}
                className="primary-btn"
                onClick={checkout}
              >
                {t("button.7")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function CheckBoxComp({ id, onChange, label }) {
  const [checked, setChecked] = useState(false);

  function onClick() {
    onChange();
    setChecked(!checked);
  }

  return (
    <div
      className={`flex items-start gap-x-2 px-3 pt-3 pb-2 w-full rounded-xl transition-all duration-500 ${
        checked && "bg-gray-300 shadow-jubilation"
      } `}
      onChange={onClick}
    >
      <div>
        <input
          className="h-4 w-4 rounded mt-1.5 cursor-pointer accent-primaryBlue border-gray-300 text-indigo-600 focus:ring-indigo-600"
          type="checkbox"
          id={id}
          checked={checked}
        />
      </div>
      <label
        htmlFor={id}
        className="-mt-[2px] cursor-pointer text-primaryBlue text-lg w-full"
      >
        {label}
      </label>
    </div>
  );
}
