import RevealingFromBottom from "../Common/RevealingFromBottom";

export default function SingleFAQ({ faq, idx }) {
  return (
    <div>
      <RevealingFromBottom
        extraDelay={idx / 10 + 0.1}
        duration={0.5}
        className="text-base font-semibold leading-7 text-gray-900"
      >
        {faq.question}
      </RevealingFromBottom>
      <RevealingFromBottom
        extraDelay={idx / 10 + 0.1}
        duration={0.5}
        className="mt-2 text-base leading-7 text-gray-600"
      >
        {faq.answer}
      </RevealingFromBottom>
    </div>
  );
}
