import { useScrollAnimate } from "../../hooks/useScrollAnimate";
import { motion } from "framer-motion";

export default function AnimatedTitle({
  children,
  className = "",
  duration = 0.25,
  extraDelay = 0,
}) {
  const { ref, controls } = useScrollAnimate();

  const textAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <p ref={ref} className={className}>
      {children.split(" ").map((el, i) => (
        <motion.span
          variants={textAnimation}
          initial="hidden"
          animate={controls}
          transition={{
            duration: duration,
            delay: extraDelay + i / 8,
          }}
          key={i}
        >
          {el}{" "}
        </motion.span>
      ))}
    </p>
  );
}
