import { useScrollAnimate } from "../../hooks/useScrollAnimate";
import { motion } from "framer-motion";

export default function RevealingFromBottom({
  children,
  className = "",
  extraDelay = 0,
  duration = 0.25,
}) {
  const { ref, controls } = useScrollAnimate();

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, translateY: 50 }}
      variants={{
        hidden: { opacity: 0, translateY: 50 },
        visible: { opacity: 1, translateY: 0 },
      }}
      animate={controls}
      transition={{
        duration: duration,
        delay: extraDelay + 0.4,
        ease: "easeOut",
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
}
