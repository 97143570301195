import useMapState from "../helpers/mapState";
import { useTranslation } from "react-i18next";

export default function Loader() {
  const { loaded } = useMapState();
  const { t } = useTranslation("global");

  return (
    <>
      {loaded && (
        <div className="flex items-center pt-5 gap-x-4 animate-pulse">
          <p className="text-2xl text-primaryBlue font-semibold">
            {t("flowtexts.4")}
          </p>

          <div className="flex space-x-2 justify-center items-center mt-3">
            <div className="h-4 w-4 bg-primaryBlue/30 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
            <div className="h-4 w-4 bg-primaryBlue/30 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div className="h-4 w-4 bg-primaryBlue/30 rounded-full animate-bounce"></div>
          </div>
        </div>
      )}
    </>
  );
}
