import { MdFileDownload } from "react-icons/md";
import { handleDownloadClick } from "../../../helpers/handleDownloadClick";

export default function DownloadButton({ index }) {
  return (
    <button
      onClick={handleDownloadClick}
      disabled={index === 1 || index === 2}
      className="flex justify-between w-full disabled:opacity-50 group hover:shadow-none border-2 border-transparent hover:border-gray-300 disabled:hover:border-transparent transition-all rounded-lg overflow-hidden items-center gap-x-2 mt-4 bg-white/50 shadow-intense disabled:shadow-none"
    >
      <p className="text-center w-full text-primaryBlue font-medium">
        <span>Download</span>
      </p>
      <p className="bg-primaryBlue p-2.5 group-hover:brightness-125 transition-all">
        <MdFileDownload className="text-white text-xl" />
      </p>
    </button>
  );
}
