import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { get as getProjection } from "ol/proj";

export function getUrl(latrd, lngrd) {
  const url = [
    `https://service.pdok.nl/lv/bag/wfs/v2_0?request=GetFeature&service=WFS&version=1.1.0&bbox=${
      latrd - 200
    },${lngrd - 200},${latrd + 200},${
      lngrd + 200
    }&outputFormat=application%2Fjson%3B%20subtype%3Dgeojson&typeName=bag:pand`,
  ];

  return url;
}

export function getBgt(latrd, lngrd) {
  const bgt = `https://service.pdok.nl/kadaster/kadastralekaart/wfs/v5_0?request=GetFeature&service=WFS&version=1.1.0&bbox=${
    latrd - 200
  },${lngrd - 200},${latrd + 200},${
    lngrd + 200
  }&outputFormat=application%2Fjson%3B%20subtype%3Dgeojson&typeName=kadastralekaart:Perceel`;

  return bgt;
}

export function getDutchProjuction() {
  proj4.defs(
    "EPSG:28992",
    "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.999908 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +units=m +no_defs"
  );

  register(proj4);
  const dutchProjuction = getProjection("EPSG:28992");

  return dutchProjuction;
}

export function getPayload(latrd, lngrd) {
  const payload = {
    featuretypes: ["pand", "gebouwinstallatie", "overigbouwwerk"],
    format: "citygml",
    geofilter: `POLYGON((${latrd - 1} ${lngrd - 1},${latrd + 1} ${lngrd + 1},${
      latrd + 1
    } ${lngrd - 1},${latrd - 1} ${lngrd + 1},${latrd - 1} ${lngrd - 1}))`,
  };

  return payload;
}
