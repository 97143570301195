import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";
import InputComp from "../../Common/InputComp";

export default function AdjPlots() {
  const { t } = useTranslation("global");

  const {
    cadstralcode,
    setcadstralcode,
    adjPlots,
    setadjPlots,
    startBeg,
    setstartBeg,
    setshowMsgHint,
    setshowHintbox,
    setdisplayMsg,
    setdisplayMsgh1,
  } = useMapState();

  const showSituationclass = document.querySelectorAll(".showSituationclass");

  const handleHaveCadastralCode = (msg) => {
    if (msg === "no") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.101"));
      setdisplayMsgh1(t("flow.1"));

      showSituationclass?.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      setadjPlots(false);
    } else {
      setstartBeg(true);
    }
  };

  const submitCadastralCode = () => {
    setshowHintbox(true);
    setshowMsgHint(true);
    setdisplayMsg(t("flow.101"));
    setdisplayMsgh1(t("flow.1"));
    showSituationclass.forEach((item) => {
      item.classList.add("showitem");
      item.classList.remove("hideitem");
    });
    setadjPlots(false);
  };

  return (
    <div>
      {adjPlots && (
        <div className="z-30 questContainer">
          {!startBeg && (
            <div>
              <div>
                <p className="text-lg text-primaryBlue">
                  {t("flow.60").split(".")[0]}
                </p>
                <p className="text-lg text-gray-600 mt-3">
                  {t("flow.60").split(".")[1]}
                </p>
              </div>

              <div className="flex gap-x-2 mt-8">
                <button
                  className="primary-btn !w-[140px]"
                  onClick={() => handleHaveCadastralCode("yes")}
                >
                  {t("yesno.1")}
                </button>
                <button
                  className="primary-btn !text-primaryBlue !bg-white !border-[3px] !border-primaryBlue !w-[140px]"
                  onClick={() => handleHaveCadastralCode("no")}
                >
                  {t("yesno.2")}
                </button>
              </div>
            </div>
          )}

          {startBeg && (
            <>
              <p className="text-lg text-primaryBlue mt-6 mb-4">
                Als er meer dan 1 perceel in uw eigendom grenst, scheidt de
                perceelsnummers dan door er een komma tussen te plaatsen.{" "}
              </p>

              <InputComp
                label="Neem de perceelnummer(s) over uit de kaart"
                name="cadastralcode"
                placeholder="1234,..."
                onChange={(e) => {
                  setcadstralcode(e.target.value);
                }}
                value={cadstralcode}
              />

              <p className="mt-2 text-sm font-semibold text-secondaryOrange">
                (tip: zoom eerst uit in de kaart voor een beter overzicht van
                aangrenzende percelen)
              </p>

              <button
                className="primary-btn !mt-4"
                onClick={submitCadastralCode}
              >
                {t("button.5")}
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
