import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useScrollAnimate } from "../../hooks/useScrollAnimate";
import AnimatedTile from "../Common/AnimatedTitle";
import RevealingFromBottom from "../Common/RevealingFromBottom";
import ScaleOut from "../Common/ScaleOut";
import { useTranslation } from "react-i18next";
import useScrollToTop from "../../hooks/useScrollToTop";

export default function Disclaimer() {
  const { t } = useTranslation("global");
  const { ref, controls } = useScrollAnimate();

  useScrollToTop();

  return (
    <div className="h-[90vh] px-5 flex items-center justify-center">
      <div className="flex max-w-5xl mx-auto rounded-2xl overflow-hidden shadow-jubilation">
        <div className="bg-primaryBlue p-8">
          <AnimatedTile
            extraDelay={0.5}
            duration={0.5}
            className="text-3xl lg:text-5xl text-white font-bold"
          >
            {t("disclaimer.title")}
          </AnimatedTile>

          <div className="text-primaryGray mt-4 space-y-3">
            {t("disclaimer.content")
              .split("|")
              .map((text, index) => (
                <RevealingFromBottom
                  extraDelay={0.5 + index / 10}
                  duration={0.7}
                >
                  {text}
                </RevealingFromBottom>
              ))}
          </div>

          <div className="flex items-center gap-x-4 mt-14">
            <ScaleOut delay={0.8} duration={0.5}>
              <Link to={process.env.PUBLIC_URL}>
                <button className="bg-white text-primaryBlue rounded-xl shadow-warm py-2 px-4 shadow-white/30 hover:scale-105 hover:shadow-none transition-all font-medium hover:font-semibold w-[160px]">
                  <span> {t("disclaimer.btn1")}</span>
                </button>
              </Link>
            </ScaleOut>

            <ScaleOut delay={0.9} duration={0.5}>
              <Link to="/contact">
                <button className="bg-white text-primaryBlue rounded-xl shadow-warm py-2 px-4 shadow-white/30 hover:scale-105 hover:shadow-none transition-all font-medium hover:font-semibold w-[160px]">
                  <span> {t("disclaimer.btn2")}</span>{" "}
                </button>
              </Link>
            </ScaleOut>
          </div>
        </div>

        <motion.img
          ref={ref}
          initial={{ opacity: 0, scale: 0 }}
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1 },
          }}
          animate={controls}
          transition={{ delay: 0.6, duration: 0.7, ease: "easeIn" }}
          className="h-[300px] lg:h-[400px] aspect-auto my-auto mx-auto"
          src={`${process.env.PUBLIC_URL}/disclaimer/disclaimer.png`}
          alt=""
        />
      </div>
    </div>
  );
}
