import Map from "ol/Map";
import View from "ol/View";
import Feature from "ol/Feature";
import { Point, Polygon } from "ol/geom";
import { GeoJSON, WMTSCapabilities } from "ol/format";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import { Draw, Snap } from "ol/interaction";
import { OSM, Vector as VectorSource, WMTS } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { optionsFromCapabilities } from "ol/source/WMTS";
import JSZip from "jszip";
import { parseString } from "xml2js";
import { getBgt, getPayload, getUrl } from "../data/urls";
import { createVectors } from "../components/Map/MainForm/MapComponents/createVectors";
import { getRandomColor } from "./getRandomColor";

export const searchHouse = ({
  latrd,
  lngrd,
  dutchProjuction,
  mapRef,
  setsearchforHouse,
  setloaded,
  setstartsarch,
  setshow,
  setcoordinates,
  setdrawnow,
  setshowdrawtext,
  setadjPlots,
  setshowHintbox,
  setshowMsgHint,
  setdisplayMsg,
  setdisplayMsgh1,
  setsituation,
  setdrawMiss,
  setdrawBuilding,
  setanotherMiss,
  setmainBuild,
  setisRes,
  setisOriginal,
  setaskMissFirst,
  setreannov,
  coordinates,
  setfeatureList,
  featureList,
  situation,
  lat,
  t,
  setEnableContinue,
}) => {
  let snapInteractionbgt;
  let areal;

  let draw = null;

  const start = document.querySelectorAll(".start");
  const showDrawHouse = document.querySelectorAll(".showDrawHouse");
  const resMissyes = document.querySelectorAll(".resMissyes");
  const nostarttwo = document.querySelectorAll(".nostarttwo");
  const yesnodrawConfirmed = document.querySelectorAll(".yesnodrawConfirmed");
  const drawConfirmedyes = document.querySelectorAll(".drawConfirmedyes");
  const starttwo = document.querySelectorAll(".starttwo");
  const showSituationclass = document.querySelectorAll(".showSituationclass");
  const anotherMissnoaskMissFirst = document.querySelectorAll(
    ".anotherMissnoaskMissFirst"
  );
  const drawSecondLine = document.querySelectorAll(".drawSecondLine");
  const nodrawLine = document.querySelectorAll(".nodrawLine");
  const nodrawBuilding = document.querySelectorAll(".nodrawBuilding");
  const showdrawHouseSecond = document.querySelectorAll(".showdrawHouseSecond");
  const nostartthree = document.querySelectorAll(".nostartthree");
  const startthreeyes = document.querySelectorAll(".startthreeyes");
  const startthreeclass = document.querySelectorAll(".startthreeclass");
  const undo = document.getElementById("undo");

  undo?.classList.add("showitem");
  undo?.classList.remove("hideitem");
  setsearchforHouse(false);
  var index = 0;
  setloaded(true);
  setstartsarch(false);
  setshow(true);
  const raster = new TileLayer({
    source: new OSM({
      attributions: [],
    }),
  });

  const url = getUrl(latrd, lngrd);
  const bgt = getBgt(latrd, lngrd);

  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      fetch(bgt)
        .then((resp) => resp.json())
        .then((data2) => {
          const geojsonFormat = new GeoJSON();

          const features2 = geojsonFormat.readFeatures(data2, {
            featureProjection: dutchProjuction,
          });

          const payload = getPayload(latrd, lngrd);

          // Construct the URL
          const url = "https://api.pdok.nl/lv/bgt/download/v1_0/full/custom";

          // Send a POST request with the payload
          fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then(async (data) => {
              let response;
              let bgtdata;

              while (true) {
                response = await fetch(
                  `https://api.pdok.nl/lv/bgt/download/v1_0/full/custom/${data["downloadRequestId"]}/status`
                );

                if (response.status === 201) {
                  bgtdata = await response.json();
                  break;
                } else {
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                }
              }

              try {
                const response = await fetch(
                  `https://api.pdok.nl${bgtdata["_links"]["download"]["href"]}`
                );
                const zipData = await response.arrayBuffer();

                const zip = new JSZip();
                const zipContents = await zip.loadAsync(zipData);

                const gmlFilePand = zipContents.file("bgt_pand.gml");
                const gmlFileAdd = zipContents.file(
                  "bgt_gebouwinstallatie.gml"
                );
                const gmlFileOver = zipContents.file("bgt_overigbouwwerk.gml");
                const xmlData = await gmlFilePand.async("string");
                const xmlDataAdd = await gmlFileAdd.async("string");
                const xmlDataOver = await gmlFileOver.async("string");
                const vectorSourcegml = new VectorSource();

                parseString(xmlData, (err, result) => {
                  if (err) {
                    console.error("XML Parsing Error:", err);
                    return;
                  }

                  result.CityModel.cityObjectMember.forEach((member) => {
                    let poslist;

                    if (
                      member &&
                      member.BuildingPart &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"][0]["gml:Polygon"] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"][0]["gml:Polygon"][0][
                        "gml:exterior"
                      ] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"][0]["gml:Polygon"][0][
                        "gml:exterior"
                      ][0]["gml:LinearRing"] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"][0]["gml:Polygon"][0][
                        "gml:exterior"
                      ][0]["gml:LinearRing"][0]["gml:posList"] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"][0]["gml:Polygon"][0][
                        "gml:exterior"
                      ][0]["gml:LinearRing"][0]["gml:posList"][0]["_"]
                    ) {
                      if (!member.BuildingPart[0]["imgeo:eindRegistratie"]) {
                        poslist =
                          member.BuildingPart[0][
                            "imgeo:geometrie2dGrondvlak"
                          ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                            "gml:Polygon"
                          ][0]["gml:exterior"][0]["gml:LinearRing"][0][
                            "gml:posList"
                          ][0]["_"].split(" ");
                        const coordinatePairs = [];
                        const coordinatesgml = [];

                        for (let i = 0; i < poslist.length; i += 2) {
                          const x = parseFloat(poslist[i]);
                          const y = parseFloat(poslist[i + 1]);
                          coordinatePairs.push([x, y]);
                        }
                        coordinatesgml.push(coordinatePairs);

                        const newgmlFeature = new Feature({
                          geometry: new Polygon(coordinatesgml),
                        });

                        vectorSourcegml.addFeature(newgmlFeature);
                      }
                    } else if (
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"][0]["gml:Polygon"][0][
                        "gml:exterior"
                      ][0]["gml:Ring"][0]["gml:curveMember"][0]["gml:Curve"][0][
                        "gml:segments"
                      ][0] &&
                      member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                        "gml:MultiSurface"
                      ][0]["gml:surfaceMember"][0]["gml:Polygon"][0][
                        "gml:exterior"
                      ][0]["gml:Ring"][0]["gml:curveMember"][0]["gml:Curve"][0][
                        "gml:segments"
                      ][0]["gml:Arc"]
                    ) {
                      poslist =
                        member.BuildingPart[0]["imgeo:geometrie2dGrondvlak"][0][
                          "gml:MultiSurface"
                        ][0]["gml:surfaceMember"][0]["gml:Polygon"][0][
                          "gml:exterior"
                        ][0]["gml:Ring"][0]["gml:curveMember"][0][
                          "gml:Curve"
                        ][0]["gml:segments"][0]["gml:Arc"];
                      poslist.forEach((poly) => {
                        const newPoly = poly["gml:posList"][0]["_"].split(" ");
                        const coordinatePairs = [];
                        const coordinatesgml = [];

                        for (let i = 0; i < newPoly.length; i += 2) {
                          const x = parseFloat(newPoly[i]);
                          const y = parseFloat(newPoly[i + 1]);
                          coordinatePairs.push([x, y]);
                        }
                        coordinatesgml.push(coordinatePairs);

                        const newgmlFeature = new Feature({
                          geometry: new Polygon(coordinatesgml),
                        });

                        vectorSourcegml.addFeature(newgmlFeature);
                        if (
                          member.BuildingPart[0][
                            "imgeo:geometrie2dGrondvlak"
                          ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                            "gml:Polygon"
                          ][0]["gml:exterior"][0]["gml:Ring"][0][
                            "gml:curveMember"
                          ][0]["gml:Curve"][0]["gml:segments"][0] &&
                          member.BuildingPart[0][
                            "imgeo:geometrie2dGrondvlak"
                          ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                            "gml:Polygon"
                          ][0]["gml:exterior"][0]["gml:Ring"][0][
                            "gml:curveMember"
                          ][0]["gml:Curve"][0]["gml:segments"][0][
                            "gml:LineStringSegment"
                          ]
                        ) {
                          poslist =
                            member.BuildingPart[0][
                              "imgeo:geometrie2dGrondvlak"
                            ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                              "gml:Polygon"
                            ][0]["gml:exterior"][0]["gml:Ring"][0][
                              "gml:curveMember"
                            ][0]["gml:Curve"][0]["gml:segments"][0][
                              "gml:LineStringSegment"
                            ];
                          poslist.forEach((poly) => {
                            const newPoly =
                              poly["gml:posList"][0]["_"].split(" ");
                            const coordinatePairs = [];
                            const coordinatesgml = [];

                            for (let i = 0; i < newPoly.length; i += 2) {
                              const x = parseFloat(newPoly[i]);
                              const y = parseFloat(newPoly[i + 1]);
                              coordinatePairs.push([x, y]);
                            }
                            coordinatesgml.push(coordinatePairs);
                            const newgmlFeature = new Feature({
                              geometry: new Polygon(coordinatesgml),
                            });
                            vectorSourcegml.addFeature(newgmlFeature);
                          });
                        }
                      });
                    }
                  });
                });
                parseString(xmlDataAdd, (err, result2) => {
                  if (err) {
                    console.error("XML Parsing Error:", err);
                    return;
                  }
                  if (result2.CityModel.cityObjectMember) {
                    result2.CityModel.cityObjectMember.forEach((member2) => {
                      let poslist;
                      if (
                        member2 &&
                        member2.BuildingInstallation &&
                        member2.BuildingInstallation[0][
                          "imgeo:geometrie2dGebouwInstallatie"
                        ] &&
                        member2.BuildingInstallation[0][
                          "imgeo:geometrie2dGebouwInstallatie"
                        ][0]["gml:Polygon"] &&
                        member2.BuildingInstallation[0][
                          "imgeo:geometrie2dGebouwInstallatie"
                        ][0]["gml:Polygon"][0]["gml:exterior"] &&
                        member2.BuildingInstallation[0][
                          "imgeo:geometrie2dGebouwInstallatie"
                        ][0]["gml:Polygon"][0]["gml:exterior"][0][
                          "gml:LinearRing"
                        ] &&
                        member2.BuildingInstallation[0][
                          "imgeo:geometrie2dGebouwInstallatie"
                        ][0]["gml:Polygon"][0]["gml:exterior"][0][
                          "gml:LinearRing"
                        ][0]["gml:posList"] &&
                        member2.BuildingInstallation[0][
                          "imgeo:geometrie2dGebouwInstallatie"
                        ][0]["gml:Polygon"][0]["gml:exterior"][0][
                          "gml:LinearRing"
                        ][0]["gml:posList"][0]["_"]
                      ) {
                        poslist =
                          member2.BuildingInstallation[0][
                            "imgeo:geometrie2dGebouwInstallatie"
                          ][0]["gml:Polygon"][0]["gml:exterior"][0][
                            "gml:LinearRing"
                          ][0]["gml:posList"][0]["_"].split(" ");
                        const coordinatePairs = [];
                        const coordinatesgml = [];

                        for (let i = 0; i < poslist.length; i += 2) {
                          const x = parseFloat(poslist[i]);
                          const y = parseFloat(poslist[i + 1]);
                          coordinatePairs.push([x, y]);
                        }
                        coordinatesgml.push(coordinatePairs);

                        const newgmlFeature = new Feature({
                          geometry: new Polygon(coordinatesgml),
                        });

                        vectorSourcegml.addFeature(newgmlFeature);
                      } else {
                      }
                    });
                  }
                });
                parseString(xmlDataOver, (err, result) => {
                  if (err) {
                    console.error("XML Parsing Error:", err);
                    return;
                  }
                  result.CityModel.cityObjectMember.forEach((member) => {
                    let poslist;
                    if (
                      member &&
                      member["imgeo:OverigBouwwerk"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:Polygon"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:Polygon"][0]["gml:exterior"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:Polygon"][0]["gml:exterior"][0][
                        "gml:LinearRing"
                      ] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:Polygon"][0]["gml:exterior"][0][
                        "gml:LinearRing"
                      ][0]["gml:posList"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:Polygon"][0]["gml:exterior"][0][
                        "gml:LinearRing"
                      ][0]["gml:posList"][0]["_"]
                    ) {
                      poslist =
                        member["imgeo:OverigBouwwerk"][0][
                          "imgeo:geometrie2dOverigeConstructie"
                        ][0]["gml:Polygon"][0]["gml:exterior"][0][
                          "gml:LinearRing"
                        ][0]["gml:posList"][0]["_"].split(" ");
                      const coordinatePairs = [];
                      const coordinatesgml = [];

                      for (let i = 0; i < poslist.length; i += 2) {
                        const x = parseFloat(poslist[i]);
                        const y = parseFloat(poslist[i + 1]);
                        coordinatePairs.push([x, y]);
                      }
                      coordinatesgml.push(coordinatePairs);

                      const newgmlFeature = new Feature({
                        geometry: new Polygon(coordinatesgml),
                      });

                      vectorSourcegml.addFeature(newgmlFeature);
                    } else if (
                      member &&
                      member["imgeo:OverigBouwwerk"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0]["gml:surfaceMember"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                        "gml:Polygon"
                      ] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                        "gml:Polygon"
                      ][0]["gml:exterior"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                        "gml:Polygon"
                      ][0]["gml:exterior"][0]["gml:LinearRing"] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                        "gml:Polygon"
                      ][0]["gml:exterior"][0]["gml:LinearRing"][0][
                        "gml:posList"
                      ] &&
                      member["imgeo:OverigBouwwerk"][0][
                        "imgeo:geometrie2dOverigeConstructie"
                      ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                        "gml:Polygon"
                      ][0]["gml:exterior"][0]["gml:LinearRing"][0][
                        "gml:posList"
                      ][0]["_"]
                    ) {
                      poslist =
                        member["imgeo:OverigBouwwerk"][0][
                          "imgeo:geometrie2dOverigeConstructie"
                        ][0]["gml:MultiSurface"][0]["gml:surfaceMember"][0][
                          "gml:Polygon"
                        ][0]["gml:exterior"][0]["gml:LinearRing"][0][
                          "gml:posList"
                        ][0]["_"].split(" ");
                      const coordinatePairs = [];
                      const coordinatesgml = [];

                      for (let i = 0; i < poslist.length; i += 2) {
                        const x = parseFloat(poslist[i]);
                        const y = parseFloat(poslist[i + 1]);
                        coordinatePairs.push([x, y]);
                      }
                      coordinatesgml.push(coordinatePairs);

                      const newgmlFeature = new Feature({
                        geometry: new Polygon(coordinatesgml),
                      });

                      vectorSourcegml.addFeature(newgmlFeature);
                    }
                  });
                });
                const vectorLayergml = new VectorLayer({
                  source: vectorSourcegml,
                  style: new Style({
                    fill: new Fill({
                      color: "rgba(100,180,140,0.03)",
                    }),
                    stroke: new Stroke({
                      color: "rgba(0,0,0,0.3)",
                      width: 1.5,
                    }),
                  }),
                });

                snapInteractionbgt = new Snap({
                  source: vectorLayergml.getSource(),
                  pixelTolerance: 30,
                  vertex: true,
                });
                map.addInteraction(snapInteractionbgt);
                map.addLayer(vectorLayergml);
                setloaded(false);
                setadjPlots(true);

                setshowdrawtext(true);
              } catch (error) {
                console.error("An error occurred:", error);
              }
            })
            .catch((error) => {
              console.error("Fetch error:", error);
            });

          const {
            drawVectorPoly,
            drawVectorLine,
            vectorLayer,
            vectorLayerbgt,
            vector,
            drawVector,
          } = createVectors(geojsonFormat, dutchProjuction, data, features2);

          const map = new Map({
            layers: [raster, vectorLayer, vectorLayerbgt],
            target: mapRef.current,
            view: new View({
              center: [latrd, lngrd],
              projection: dutchProjuction,
              zoom: 21,
            }),
          });

          const wmtscap = new WMTSCapabilities();

          fetch(
            "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0?request=GetCapabilities&service=wmts"
          )
            .then(function (response) {
              return response.text();
            })
            .then(function (text) {
              const result = wmtscap.read(text);
              const options = optionsFromCapabilities(result, {
                layer: "2023_orthoHR",
                matrixSet: dutchProjuction,
              });
              areal = new TileLayer({
                source: new WMTS(options),
                opacity: 0.55,
              });
              map.addLayer(areal);
              areal.setVisible(false);

              const handleContinueDrawMissingSecond = document.getElementById(
                "handleContinueDrawMissingSecond"
              );

              handleContinueDrawMissingSecond.addEventListener("click", () => {
                setdrawnow(false);
                setshowHintbox(false);
                setshowMsgHint(false);
                setdisplayMsg(t("flow.102"));
                setdisplayMsgh1(t("flow.2"));
                setdrawBuilding(true);
                setanotherMiss(false);
                anotherMissnoaskMissFirst.forEach((item) => {
                  item.classList.remove("showitem");
                  item.classList.add("hideitem");
                });
                nodrawBuilding.forEach((item) => {
                  item.classList.add("showitem");
                  item.classList.remove("hideitem");
                });

                messageBuildingDeleted.classList.add("showitem");
                messageBuildingDeleted.classList.remove("hideitem");

                addInteraction("LineString");
                map.addInteraction(snapInteractionbgt);
                areal.setVisible(false);
              });
            });
          const vectorsourceParcels = new VectorSource();

          features2.forEach((ftr) => {
            const newFtr = new Feature({
              geometry: new Point([
                ftr["values_"]["perceelnummerPlaatscoordinaatX"],
                ftr["values_"]["perceelnummerPlaatscoordinaatY"],
              ]),
            });
            newFtr.set("name", "" + ftr["values_"]["perceelnummer"]);
            newFtr.setStyle(() => {
              return new Style({
                text: new Text({
                  text: newFtr.get("name"),
                  scale: 1.0,
                  textBaseline: "bottom",
                  fill: new Fill({
                    color: "black",
                  }),
                  stroke: new Stroke({
                    color: "black",
                    width: 1,
                  }),
                }),
              });
            });
            vectorsourceParcels.addFeatures([newFtr]);
          });

          const vectorLayerParcels = new VectorLayer({
            source: vectorsourceParcels,
            style: (ftr) => {
              return ftr.get("name").getStyle().getText();
            },
          });
          map.addLayer(vectorLayerParcels);

          const snap = new Snap({
            source: drawVector.getSource(),
          });

          const snapInteraction = new Snap({
            source: vectorLayer.getSource(),
            pixelTolerance: 30,
            vertex: true,
          });

          const handleReannovSecond = document.getElementById(
            "handleReannovSecond"
          );

          const sityesbuildyes = document.getElementById("sityesbuildyes");

          const allitems = document.querySelectorAll(".allitems");
          allitems.forEach((item) => {
            item.classList.add("showitem");
            item.classList.remove("hideitem");
          });
          const nostart = document.querySelectorAll(".nostart");
          nostart.forEach((item) => {
            item.classList.add("showitem");
          });
          start.forEach((item) => {
            item.classList.add("hideitem");
          });
          const nodrawMiss = document.querySelectorAll(".nodrawMiss");
          yesnodrawConfirmed.forEach((item) => {
            item.classList.add("hideitem");
          });
          nodrawMiss.forEach((item) => {
            item.classList.add("hideitem");
          });
          const mainBuild = document.querySelectorAll(".mainBuild");
          const nomainBuild = document.querySelectorAll(".nomainBuild");
          mainBuild.forEach((item) => {
            item.classList.add("hideitem");
          });
          nomainBuild.forEach((item) => {
            item.classList.add("hideitem");
          });
          const nocontinueFlow = document.querySelectorAll(".nocontinueFlow");
          const noisRes = document.querySelectorAll(".noisRes");
          nocontinueFlow.forEach((item) => {
            item.classList.add("hideitem");
          });
          noisRes.forEach((item) => {
            item.classList.add("hideitem");
          });
          const isRes = document.querySelectorAll(".isRes");
          const isOriginal = document.querySelectorAll(".isOriginal");
          isRes.forEach((item) => {
            item.classList.add("hideitem");
          });
          isOriginal.forEach((item) => {
            item.classList.add("hideitem");
          });
          const noisOriginal = document.querySelectorAll(".noisOriginal");
          const continueFlow = document.querySelectorAll(".continueFlow");
          noisOriginal.forEach((item) => {
            item.classList.add("hideitem");
          });
          continueFlow.forEach((item) => {
            item.classList.add("hideitem");
          });
          anotherMissnoaskMissFirst.forEach((item) => {
            item.classList.add("hideitem");
          });
          nodrawBuilding.forEach((item) => {
            item.classList.add("hideitem");
          });
          drawSecondLine.forEach((item) => {
            item.classList.add("hideitem");
          });
          nodrawLine.forEach((item) => {
            item.classList.add("hideitem");
          });
          showdrawHouseSecond.forEach((item) => {
            item.classList.add("hideitem");
          });
          nostartthree.forEach((item) => {
            item.classList.add("hideitem");
          });
          const resMissno = document.querySelectorAll(".resMissno");
          startthreeyes.forEach((item) => {
            item.classList.add("hideitem");
          });
          startthreeclass.forEach((item) => {
            item.classList.add("hideitem");
          });
          resMissno.forEach((item) => {
            item.classList.add("hideitem");
          });
          const drawMissSecond = document.querySelectorAll(".drawMissSecond");
          const noanotherMiss = document.querySelectorAll(".noanotherMiss");
          drawMissSecond.forEach((item) => {
            item.classList.add("hideitem");
          });
          noanotherMiss.forEach((item) => {
            item.classList.add("hideitem");
          });
          const startthreeno = document.querySelectorAll(".startthreeno");
          const finished = document.querySelectorAll(".finished");
          startthreeno.forEach((item) => {
            item.classList.add("hideitem");
          });
          finished.forEach((item) => {
            item.classList.add("hideitem");
          });
          drawConfirmedyes.forEach((item) => {
            item.classList.add("hideitem");
          });
          showDrawHouse.forEach((item) => {
            item.classList.add("hideitem");
          });
          const starttwoyes = document.querySelectorAll(".starttwoyes");
          nostarttwo.forEach((item) => {
            item.classList.add("hideitem");
          });
          starttwoyes.forEach((item) => {
            item.classList.add("hideitem");
            item.addEventListener("click", () => {});
          });
          const drawMiss = document.querySelectorAll(".drawMiss");
          resMissyes.forEach((item) => {
            item.classList.add("hideitem");
          });
          drawMiss.forEach((item) => {
            item.classList.add("hideitem");
          });

          const anotherMissyes = document.querySelectorAll(".anotherMissyes");
          anotherMissyes.forEach((item) => {
            item.classList.add("hideitem");
          });

          function addInteraction(type) {
            const value = type;

            map.removeInteraction(draw);

            if (value !== "None") {
              draw = new Draw({
                type: type, // Not specifying the source here to manually manage the feature addition
              });

              draw.on("drawend", (event) => {
                const feature = event.feature;
                const geometry = feature.getGeometry();
                let isValid = false; // A flag to determine if the feature is valid

                if (geometry.getType() === "Polygon") {
                  const area = geometry.getArea();
                  isValid = area >= 2;
                } else if (geometry.getType() === "LineString") {
                  const length = geometry.getLength();
                  isValid = length >= 2;
                }

                if (isValid) {
                  vector.addFeature(feature);

                  setcoordinates(geometry.getCoordinates());

                  const newStyle = new Style({
                    fill: new Fill({
                      color: getRandomColor(index),
                    }),
                    stroke: new Stroke({
                      color: getRandomColor(index),
                      width: 4,
                    }),
                  });

                  feature.setStyle(newStyle);
                  index++;

                  setEnableContinue(true);
                } else {
                  vector.removeFeature(feature); // Remove the feature if it doesn't meet the conditions

                  if (geometry.getType() === "Polygon") {
                    alert(t("mapLengthAlert.polygonAlert"));
                  } else if (geometry.getType() === "LineString") {
                    alert(t("mapLengthAlert.lineAlert"));
                  }
                }
              });

              map.addInteraction(draw);
              map.addInteraction(snapInteraction); // Ensure snapInteraction is correctly defined
              map.addInteraction(snap); // Ensure snap is correctly defined
            }
          }

          undo?.addEventListener("click", function () {
            draw.removeLastPoint();
          });

          const dontDrawDeletedBuilding = document.getElementById(
            "dontDrawDeletedBuilding"
          );

          dontDrawDeletedBuilding.addEventListener("click", function () {
            setdrawnow(true);

            if (situation === "yes") {
              setEnableContinue(false);

              yesnodrawConfirmed.forEach((item) => {
                item.classList.add("showitem");
                item.classList.remove("hideitem");
              });
            } else {
              setEnableContinue(false);

              drawSecondLine.forEach((item) => {
                item.classList.add("showitem");
                item.classList.remove("hideitem");
              });
            }

            setdisplayMsg(t("flow.102"));
            setdisplayMsgh1(t("flow.2"));

            messageBuildingDeleted.classList.remove("showitem");
            messageBuildingDeleted.classList.add("hideitem");

            setshowHintbox(true);
            setshowMsgHint(true);

            setsituation("yes");
            addInteraction("LineString");
            map.addInteraction(snapInteractionbgt);
          });

          const messageBuildingDeleted = document.querySelector(
            ".messageBuildingDeleted"
          );

          const sityes = document.getElementById("sityes");

          sityes.addEventListener("click", function () {
            setshowHintbox(true);
            setshowMsgHint(true);
            setdisplayMsg(t("flow.103"));
            setdisplayMsgh1(t("flow.3"));
            areal.setVisible(true);
            start.forEach((item) => {
              item.classList.add("showitem");
              item.classList.remove("hideitem");
            });
            showSituationclass.forEach((item) => {
              item.classList.remove("showitem");
              item.classList.add("hideitem");
            });
            setsituation("no");
            setdrawMiss(true);
          });

          const sitnostart = document.getElementById("sitnostart");

          sitnostart.addEventListener("click", () => {
            setshowMsgHint(false);
            setshowHintbox(false);

            start.forEach((item) => {
              item.classList.add("showitem");
              item.classList.remove("hideitem");
            });
            showSituationclass.forEach((item) => {
              item.classList.remove("showitem");
              item.classList.add("hideitem");
            });
            messageBuildingDeleted.classList.add("showitem");
            messageBuildingDeleted.classList.remove("hideitem");

            setdrawnow(false);
            setsituation("yes");
            addInteraction("Polygon");
            map.addInteraction(snapInteractionbgt);

            // setshowHintbox(true);
            // setshowMsgHint(true);
            // setdisplayMsg(t("flow.103"));
            // setdisplayMsgh1(t("flow.3"));
            // areal.setVisible(true);
            // start.forEach((item) => {
            //   item.classList.add("showitem");
            //   item.classList.remove("hideitem");
            // });
            // showSituationclass.forEach((item) => {
            //   item.classList.remove("showitem");
            //   item.classList.add("hideitem");
            // });
            // setsituation("no");
            // setdrawMiss(true);
          });

          sityesbuildyes.addEventListener("click", () => {
            setdrawnow(true);
            setshowHintbox(true);
            setshowMsgHint(true);
            setdisplayMsg(t("flow.108"));
            setdisplayMsgh1(t("flow.8"));

            starttwo.forEach((item) => {
              item.classList.add("showitem");
              item.classList.remove("hideitem");
            });
            nostarttwo.forEach((item) => {
              item.classList.remove("showitem");
              item.classList.add("hideitem");
            });
            starttwoyes.forEach((item) => {
              item.classList.add("showitem");
              item.classList.remove("hideitem");
            });

            addInteraction("Polygon");
            map.addInteraction(snapInteractionbgt);
          });

          handleReannovSecond.addEventListener("click", () => {
            setdrawnow(true);
            setmainBuild(false);
            setisRes(false);
            setisOriginal(false);
            setdrawMiss(false);
            setaskMissFirst(false);
            setshowHintbox(true);
            setshowMsgHint(true);
            setdisplayMsg(t("flow.108"));
            setdisplayMsgh1(t("flow.8"));
            startthreeyes.forEach((item) => {
              item.classList.add("showitem");
              item.classList.remove("hideitem");
            });
            nostartthree.forEach((item) => {
              item.classList.remove("showitem");
              item.classList.add("hideitem");
            });
            startthreeclass.forEach((item) => {
              item.classList.add("showitem");
              item.classList.remove("hideitem");
            });
            setreannov("yes");
            addInteraction("Polygon");
            map.addInteraction(snapInteractionbgt);
          });

          const drawDeletedBuilding = document.getElementById(
            "drawDeletedBuilding"
          );

          const deletedBuildingText = document.querySelector(
            ".deletedBuildingText"
          );

          drawDeletedBuilding.addEventListener("click", function () {
            messageBuildingDeleted.classList.remove("showitem");
            messageBuildingDeleted.classList.add("hideitem");

            setEnableContinue(false);

            deletedBuildingText.classList.add("showitem");
            deletedBuildingText.classList.remove("hideitem");

            setdrawnow(true);
            setshowHintbox(false);
            setshowMsgHint(false);
            setdisplayMsg(t("flow.102"));
            setdisplayMsgh1(t("flow.2"));
            setsituation("yes");
            addInteraction("Polygon");
            map.addInteraction(snapInteractionbgt);
          });

          const addDeletedBuilding =
            document.getElementById("addDeletedBuilding");

          addDeletedBuilding.addEventListener("click", () => {
            addInteraction("LineString");
            map.addInteraction(snapInteractionbgt);
          });

          addInteraction("Polygon");
          map.addInteraction(snapInteraction);
          map.addInteraction(snap);
          map.addLayer(drawVectorLine);
          map.addLayer(drawVectorPoly);
          map.addLayer(drawVector);

          return () => {
            map.dispose();
          };
        });
    });
};
