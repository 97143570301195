// External Libraries
import { useTranslation } from "react-i18next";

// Internal Components
import HintBox from "./HintBox";
import PlaceConfirmed from "./PlaceConfirmed";
import Pay from "./Pay";
import All from "./All";

// Internal Helpers and Utilities
import useMapState from "../../helpers/mapState";
import { getDutchProjuction } from "../../../data/urls";
import { searchHouse } from "../../../helpers/searchHouse";

export default function MainForm({ sendReq, mapRef }) {
  const { t } = useTranslation("global");
  const dutchProjuction = getDutchProjuction();

  const {
    setstartsarch,
    setcoordinates,
    setshow,
    latrd,
    lngrd,
    setdrawnow,
    setdrawMiss,
    setshowdrawtext,
    setanotherMiss,
    setisRes,
    setisOriginal,
    setmainBuild,
    setdrawBuilding,
    setaskMissFirst,
    setsearchforHouse,
    setadjPlots,
    setshowMsgHint,
    setshowHintbox,
    setloaded,
    setdisplayMsg,
    setdisplayMsgh1,
    setsituation,
    setreannov,
    coordinates,
    setfeatureList,
    featureList,
    setdrawLine,
    setresMiss,
    situation,
    lat,
    pay,
    setEnableContinue,
    enableContinue,
  } = useMapState();

  return (
    <div>
      {!pay && (
        <>
          {" "}
          <HintBox />
          <PlaceConfirmed
            searchHouse={() =>
              searchHouse({
                latrd,
                lngrd,
                dutchProjuction,
                mapRef,
                setsearchforHouse,
                setloaded,
                setstartsarch,
                setshow,
                setcoordinates,
                setdrawnow,
                setshowdrawtext,
                setadjPlots,
                setshowHintbox,
                setshowMsgHint,
                setdisplayMsg,
                setdisplayMsgh1,
                setsituation,
                setdrawMiss,
                setdrawBuilding,
                setanotherMiss,
                setmainBuild,
                setisRes,
                setisOriginal,
                setaskMissFirst,
                setreannov,
                coordinates,
                setfeatureList,
                featureList,
                setdrawLine,
                setresMiss,
                lat,
                situation,
                t,
                setEnableContinue,
                enableContinue,
              })
            }
          />
          <All sendReq={sendReq} />{" "}
        </>
      )}

      <Pay />
    </div>
  );
}
