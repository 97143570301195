import { useTranslation } from "react-i18next";
import useMapState from "../../../../helpers/mapState";
import { useEffect } from "react";

export default function DrawComp({ handleAddToList }) {
  const { t } = useTranslation("global");

  const { isOriginal, continueFlow, setEnableContinue } = useMapState();

  useEffect(() => {
    setEnableContinue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(isOriginal || continueFlow) && (
        <>
          <div className="text-box">
            <p className="text-box text-lg text-primaryBlue">{t("flow.40")}</p>
          </div>
          <button
            className="primary-btn mt-4"
            onClick={() => handleAddToList("no")}
          >
            {t("button.3")}
          </button>
        </>
      )}
    </>
  );
}
