import { useTranslation } from "react-i18next";
import useMapState from "../helpers/mapState";

export default function Titles() {
  const { t } = useTranslation("global");

  const { pay, drawnow, showdrawtext } = useMapState();

  return (
    <>
      {showdrawtext && !pay && (
        <div>
          <p className="text-2xl text-primaryBlue font-semibold">
            {drawnow ? t("flowtexts.6") : t("flowtexts.5")}
          </p>
        </div>
      )}
    </>
  );
}
