import { useTranslation } from "react-i18next";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import AnimatedTitle from "../../Common/AnimatedTitle";
import SingleCard from "./SingleCard";

export default function Downloads() {
  const { t } = useTranslation("global");
  const { ref, controls } = useScrollAnimate();

  const data = [
    {
      img: `${process.env.PUBLIC_URL}/downloads/charts1.png`,
      title: t("chars.1"),
    },
    {
      img: `${process.env.PUBLIC_URL}/downloads/charts2.png`,
      title: t("chars.2"),
    },
    {
      img: `${process.env.PUBLIC_URL}/downloads/charts3.png`,
      title: t("chars.3"),
    },
  ];

  return (
    <div
      ref={ref}
      className="bg-[#D9D9D9] px-5 lg:px-14 xl:px-32 my-20 xl:my-0 shadow-jubilation py-10"
    >
      <AnimatedTitle className="text-4xl px-10 lg:px-0 font-semibold text-primaryBlue text-center">
        Voorbeelden en instructies
      </AnimatedTitle>

      <div className="grid grid-cols-2 lg:grid-cols-3 gap-10 mt-20">
        {data.map((singleCard, index) => (
          <SingleCard
            controls={controls}
            index={index}
            singleCard={singleCard}
          />
        ))}
      </div>
    </div>
  );
}
