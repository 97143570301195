import Step from "./Step";
import { motion } from "framer-motion";
import { useScrollAnimate } from "../../../../hooks/useScrollAnimate";

const Steps = ({ numSteps, stepsComplete }) => {
  const stepArray = Array.from(Array(numSteps).keys());
  const { ref, controls } = useScrollAnimate();

  return (
    <div ref={ref} className="grid grid-cols-8 gap-3">
      {stepArray.map((num) => {
        const stepNum = num + 1;
        const isActive = stepNum <= stepsComplete;

        return (
          <div className="flex items-center gap-x-3" key={stepNum}>
            <Step controls={controls} num={stepNum} isActive={isActive} />
            {stepNum !== stepArray.length && (
              <motion.div
                initial="hidden"
                variants={{
                  hidden: { width: "0%" },
                  visible: { width: "100%" },
                }}
                animate={controls}
                transition={{
                  duration: 0.4,
                  delay: 0.2 + num / 10,
                }}
                className="h-1 w-full rounded-full bg-gray-200 relative"
              >
                <motion.div
                  className="absolute top-0 bottom-0 left-0 bg-secondaryOrange/50 rounded-full"
                  animate={{ width: isActive ? "100%" : 0 }}
                  transition={{ ease: "easeIn", duration: 0.3 }}
                />
              </motion.div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
