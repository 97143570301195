import { create } from "zustand";

const useMapState = create((set) => ({
  checkbox1: false,
  checkbox2: false,
  checkbox3: false,
  name: "",
  houseltr: "",
  huisnummertoevoeging: "",
  email: "",
  postcode: "",
  housenb: 0,
  original: "",
  residential: "",
  main: "",
  pay: false,
  coordinates: [],
  error: "",
  iid: 1,
  startsarch: false,
  startsarchTwo: false,
  featureList: [],
  quesNb: 0,
  show: false,
  cadstralcode: "",
  lat: "",
  lng: "",
  latrd: 0,
  lngrd: 0,
  adress: "",
  drawnow: false,
  color: 0,
  placeConfirmed: true,
  drawMiss: false,
  showdrawtext: false,
  drawMissSecond: false,
  anotherMiss: false,
  isRes: false,
  isOriginal: false,
  mainBuild: false,
  drawConfirmed: false,
  finished: false,
  drawBuilding: false,
  startthree: false,
  drawLine: true,
  askMissFirst: true,
  searchforHouse: true,
  adjPlots: false,
  startBeg: false,
  showMsgHint: false,
  showHintbox: false,
  loaded: false,
  displayMsg: "",
  displayMsgh1: "",
  resMiss: false,
  showSituation: false,
  continueFlow: false,
  situation: "yes",
  situationTwo: "yes",
  reannov: "yes",
  reannovSecond: "yes",
  enableContinue: true,

  setCheckbox1: (value) => set({ checkbox1: value }),
  setCheckbox2: (value) => set({ checkbox2: value }),
  setCheckbox3: (value) => set({ checkbox3: value }),
  setname: (value) => set({ name: value }),
  sethouseltr: (value) => set({ houseltr: value }),
  sethuisnummertoevoeging: (value) => set({ huisnummertoevoeging: value }),
  setemail: (value) => set({ email: value }),
  setPostcode: (value) => set({ postcode: value }),
  setHousenb: (value) => set({ housenb: value }),
  setOriginal: (value) => set({ original: value }),
  setResidential: (value) => set({ residential: value }),
  setMain: (value) => set({ main: value }),
  setpay: (value) => set({ pay: value }),
  setcoordinates: (value) => set({ coordinates: value }),
  setError: (value) => set({ error: value }),
  setiid: (value) => set({ iid: value }),
  setstartsarch: (value) => set({ startsarch: value }),
  setstartsarchTwo: (value) => set({ startsarchTwo: value }),
  setfeatureList: (value) => set({ featureList: value }),
  setquesNb: (value) => set({ quesNb: value }),
  setshow: (value) => set({ show: value }),
  setcadstralcode: (value) => set({ cadstralcode: value }),
  setlat: (value) => set({ lat: value }),
  setlng: (value) => set({ lng: value }),
  setlatrd: (value) => set({ latrd: value }),
  setlngrd: (value) => set({ lngrd: value }),
  setadress: (value) => set({ adress: value }),
  setdrawnow: (value) => set({ drawnow: value }),
  setcolor: (value) => set({ color: value }),
  setplaceConfirmed: (value) => set({ placeConfirmed: value }),
  setdrawMiss: (value) => set({ drawMiss: value }),
  setshowdrawtext: (value) => set({ showdrawtext: value }),
  setdrawMissSecond: (value) => set({ drawMissSecond: value }),
  setanotherMiss: (value) => set({ anotherMiss: value }),
  setisRes: (value) => set({ isRes: value }),
  setisOriginal: (value) => set({ isOriginal: value }),
  setmainBuild: (value) => set({ mainBuild: value }),
  setdrawConfirmed: (value) => set({ drawConfirmed: value }),
  setFinished: (value) => set({ finished: value }),
  setdrawBuilding: (value) => set({ drawBuilding: value }),
  setstartthree: (value) => set({ startthree: value }),
  setdrawLine: (value) => set({ drawLine: value }),
  setaskMissFirst: (value) => set({ askMissFirst: value }),
  setsearchforHouse: (value) => set({ searchforHouse: value }),
  setadjPlots: (value) => set({ adjPlots: value }),
  setstartBeg: (value) => set({ startBeg: value }),
  setshowMsgHint: (value) => set({ showMsgHint: value }),
  setshowHintbox: (value) => set({ showHintbox: value }),
  setloaded: (value) => set({ loaded: value }),
  setdisplayMsg: (value) => set({ displayMsg: value }),
  setdisplayMsgh1: (value) => set({ displayMsgh1: value }),
  setresMiss: (value) => set({ resMiss: value }),
  setshowSituation: (value) => set({ showSituation: value }),
  setcontinueFlow: (value) => set({ continueFlow: value }),
  setsituation: (value) => set({ situation: value }),
  setsituationTwo: (value) => set({ situationTwo: value }),
  setreannov: (value) => set({ reannov: value }),
  setreannovSecond: (value) => set({ reannovSecond: value }),
  setEnableContinue: (value) => set({ enableContinue: value }),

  reset: () =>
    set({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      name: "",
      houseltr: "",
      huisnummertoevoeging: "",
      email: "",
      postcode: "",
      housenb: 0,
      original: "",
      residential: "",
      main: "",
      pay: false,
      coordinates: [],
      error: "",
      iid: 1,
      startsarch: false,
      startsarchTwo: false,
      featureList: [],
      quesNb: 0,
      show: false,
      cadstralcode: "",
      lat: "",
      lng: "",
      latrd: 0,
      lngrd: 0,
      adress: "",
      drawnow: false,
      color: 0,
      placeConfirmed: true,
      drawMiss: false,
      showdrawtext: false,
      drawMissSecond: false,
      anotherMiss: false,
      isRes: false,
      isOriginal: false,
      mainBuild: false,
      drawConfirmed: false,
      finished: false,
      drawBuilding: false,
      startthree: false,
      drawLine: true,
      askMissFirst: true,
      searchforHouse: true,
      adjPlots: false,
      startBeg: false,
      showMsgHint: false,
      showHintbox: false,
      loaded: false,
      displayMsg: "",
      displayMsgh1: "",
      resMiss: false,
      showSituation: false,
      continueFlow: false,
      situation: "yes",
      situationTwo: "yes",
      reannov: "yes",
      reannovSecond: "yes",
      enableContinue: true,
    }),
}));

export default useMapState;
