import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";

export default function DrawConfirmedyes({ sendReq }) {
  const { t } = useTranslation("global");

  const showDrawHouse = document.querySelectorAll(".showDrawHouse");
  const resMissyes = document.querySelectorAll(".resMissyes");
  const starttwo = document.querySelectorAll(".starttwo");
  const anotherMissnoaskMissFirst = document.querySelectorAll(
    ".anotherMissnoaskMissFirst"
  );
  const undo = document.getElementById("undo");

  const {
    original,
    setOriginal,
    residential,
    setResidential,
    main,
    setMain,
    setpay,
    coordinates,
    setcoordinates,
    iid,
    setiid,
    featureList,
    setfeatureList,
    quesNb,
    setquesNb,
    setshow,
    lat,
    setlat,
    setlng,
    setdrawnow,
    drawMiss,
    setdrawMiss,
    setdrawMissSecond,
    anotherMiss,
    setanotherMiss,
    isRes,
    setisRes,
    isOriginal,
    setisOriginal,
    mainBuild,
    setmainBuild,
    finished,
    setdrawLine,
    setshowMsgHint,
    setshowHintbox,
    setdisplayMsg,
    setdisplayMsgh1,
    resMiss,
    setresMiss,
    continueFlow,
    setcontinueFlow,
    situation,
    reannov,
    setreannov,
    setEnableContinue,
  } = useMapState();

  const handleReannov = (msg) => {
    setpay(true);
    undo?.classList.add("hideitem");
    undo?.classList.remove("showitem");
    showDrawHouse.forEach((item) => {
      item.classList.remove("showitem");
      item.classList.add("hideitem");
    });
  };

  const handleAddBuildingToList = () => {
    setdrawnow(false);
    setdisplayMsg(t("flow.110"));
    setdisplayMsgh1(t("flow.10"));
    setshowMsgHint(true);

    const features = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: coordinates,
      },
      properties: {
        id: "Oorspronkelijk hoofdgebouw",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setcoordinates("");
    }
    resMissyes.forEach((item) => {
      item.classList.add("showitem");
      item.classList.remove("hideitem");
    });
    showDrawHouse.forEach((item) => {
      item.classList.remove("showitem");
      item.classList.add("hideitem");
    });

    setdrawLine(true);
    // setshowDrawHouse(false)
    setresMiss(true);
    setreannov("yes");
  };

  const resMissFound = (msg, sit) => {
    setresMiss(false);
    setshowHintbox(false);

    // Show the {questions.1} when msg === "yes" regardless of sit
    if (msg === "yes") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.170"));
      setdisplayMsgh1(t("questions.1"));
      setcontinueFlow(false);
    }

    // Existing logic based on sit value
    if (sit === "yes") {
      if (msg === "yes") {
        setdrawMiss(true);
        setmainBuild(true);
        setMain("yes");
        setisRes(true);
        setResidential("yes");
      } else if (msg === "no") {
        setpay(true);
        undo?.classList.add("hideitem");
        undo?.classList.remove("showitem");
      }
    } else if (sit === "no") {
      if (msg === "yes") {
        setdrawMissSecond(true);
        setmainBuild(true);
        setMain("yes");
        setisRes(true);
        setResidential("yes");
      } else if (msg === "no") {
        setpay(true);
        undo?.classList.add("hideitem");
        undo?.classList.remove("showitem");
      }
    }
  };

  const handleSetOriginal = (msg, stat) => {
    setdrawnow(true);
    setOriginal(msg);
    setisOriginal(true);
    setdrawnow(true);
    if (stat === "show") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.109"));
      setdisplayMsgh1(t("flow.9"));
    }
    if (stat === "hide") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.140"));
      setdisplayMsgh1(t("flow.40"));
    }
  };

  const handleAddToList = (msg) => {
    setdrawnow(false);
    setshowHintbox(false);
    const features = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: coordinates,
      },
      properties: {
        id: iid,
        original: original === "yes" ? "ja" : "nee",
        residential: residential === "yes" ? "ja" : "nee",
        main: main === "yes" ? "ja" : "nee",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setlat("");
      setlng("");
      setResidential("");
      setMain("");
      setOriginal("");
      setcoordinates("");
      setiid(iid + 1);
    }
    if (msg === "anot") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.107"));
      setdisplayMsgh1(t("flow.7"));
      anotherMissnoaskMissFirst.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
    } else {
      setanotherMiss(true);
    }
    setquesNb(quesNb + 1);
    setshow(true);
    setmainBuild(false);
    setisRes(false);
    setisOriginal(false);
    setdrawMiss(false);
    setdrawMissSecond(true);
    if (msg === "no" || msg === "yes") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.150"));
      setdisplayMsgh1(t("flow.50"));
    }
  };

  // Second loop
  const handleContinueDrawMissing = (msg, sit) => {
    setcontinueFlow(false);
    anotherMissnoaskMissFirst.forEach((item) => {
      item.classList.remove("showitem");
      item.classList.add("hideitem");
    });
    if (sit === "yes") {
      setmainBuild(true);
      setMain("yes");
      setisRes(true);
      setResidential("yes");
    }
    if (msg === "yes") {
      setdrawMiss(true);
      setanotherMiss(false);
      setdisplayMsg(t("flow.170"));
      setdisplayMsgh1(t("questions.1"));
    } else if (msg === "no") {
      undo?.classList.add("hideitem");
      undo?.classList.remove("showitem");
      setanotherMiss(false);
      setpay(true);
    }
  };

  return (
    <div className="drawConfirmedyes">
      <div className="showDrawHouse">
        <div className="nostarttwo">
          <p className="text-primaryBlue text-lg mb-4">{t("flow.6")}</p>
          <div className="!flex !flex-row !gap-x-2 my-4 ">
            <button
              onClick={() => setEnableContinue(false)}
              className="primary-btn !w-[140px]"
              id="sityesbuildyes"
            >
              {t("yesno.1")}
            </button>
            <button
              className="primary-btn border-[3px] !w-[140px] border-primaryBlue !bg-white !text-primaryBlue"
              onClick={() => {
                handleReannov("no");
              }}
            >
              {t("yesno.2")}
            </button>
          </div>
        </div>
      </div>

      <div className="starttwoyes">
        <DrawComp handleAddBuildingToList={handleAddBuildingToList} />

        {resMiss && situation === "yes" && (
          <>
            <div className="text-box">
              <p className="text-lg text-primaryBlue mb-4">{t("flow.10")}</p>
            </div>
            <div className="flex gap-x-2 my-4">
              <button
                className="primary-btn !w-[140px] !py-1"
                onClick={() => resMissFound("yes", "yes")}
              >
                {t("yesno.1")}
              </button>
              <button
                className="primary-btn !py-1 !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
                onClick={() => resMissFound("no", "yes")}
              >
                {t("yesno.2")}
              </button>
            </div>
          </>
        )}

        {drawMiss && (
          <div>
            {mainBuild && (
              <>
                {!continueFlow && (
                  <>
                    {isRes && (
                      <div>
                        {!isOriginal && (
                          <>
                            <p className="text-box text-lg text-primaryBlue">
                              {t("questions.1")}
                            </p>

                            <div className="flex gap-x-2 my-4">
                              <button
                                className="primary-btn !w-[140px]"
                                onClick={() => handleSetOriginal("yes", "hide")}
                              >
                                {t("yesno.1")}
                              </button>
                              <button
                                className="primary-btn border-[3px] !w-[140px] border-primaryBlue !bg-white !text-primaryBlue"
                                onClick={() => handleSetOriginal("no", "hide")}
                              >
                                {t("yesno.2")}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {(isOriginal || continueFlow) && (
                  <>
                    <div className="text-box mb-4">
                      <p className="text-lg text-primaryBlue">{t("flow.40")}</p>
                    </div>
                    <button
                      className="primary-btn"
                      onClick={() => handleAddToList("yes")}
                    >
                      {t("button.3")}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {anotherMiss && situation === "yes" && (
          <>
            <div className="text-box">
              <p className="text-box text-lg text-primaryBlue">
                {t("flow.50")}
              </p>
            </div>

            <div className="flex gap-x-2 my-4">
              <button
                className="primary-btn !w-[140px]"
                onClick={() => handleContinueDrawMissing("yes", "yes")}
              >
                {t("yesno.1")}
              </button>
              <button
                className="primary-btn !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
                onClick={() => handleContinueDrawMissing("no", "yes")}
              >
                {t("yesno.2")}
              </button>
            </div>
          </>
        )}
      </div>

      {((starttwo && reannov === "no") || finished) && (
        <>
          <button className="primary-btn" id="btn" onClick={sendReq}>
            {t("button.4")}
          </button>
        </>
      )}
    </div>
  );
}

function DrawComp({ handleAddBuildingToList }) {
  const { t } = useTranslation("global");

  const { setEnableContinue, enableContinue } = useMapState();

  useEffect(() => {
    setEnableContinue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="showDrawHouse">
        <div className="text-box">
          <p className="text-lg text-primaryBlue mb-4"> {t("flow.8")}</p>
        </div>
        <button
          className="primary-btn"
          disabled={!enableContinue}
          onClick={() => handleAddBuildingToList()}
        >
          {t("button.3")}
        </button>
      </div>
    </>
  );
}
