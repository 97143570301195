import { useTranslation } from "react-i18next";

import useMapState from "../../../helpers/mapState";
import { IoMdCloseCircle } from "react-icons/io";

export default function Startsarch({ searchHouse }) {
  const { t } = useTranslation("global");

  const {
    postcode,
    housenb,
    startsarch,
    setstartsarch,
    setstartsarchTwo,
    adress,
  } = useMapState();

  const changeValues = () => {
    setstartsarch(false);
    setstartsarchTwo(false);
  };

  return (
    <>
      {startsarch && (
        <div className="bg-gray-300/50 h-screen w-screen fixed top-0 left-0 z-50">
          <div className="relative h-full w-full">
            <div className="bg-white absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] shadow-intense rounded-xl py-10 pl-10 pr-32">
              <p className="text-lg text-primaryBlue">{t("questions.9")}</p>

              <div className="flex mt-4 items-center gap-x-2 min-w-[300px]">
                <p className="text-lg font-medium">{t("questions.10")}</p>

                <p className="text-lg text-gray-500">{adress}</p>
              </div>

              <button onClick={changeValues} className="absolute top-3 right-3">
                <IoMdCloseCircle className="text-2xl hover:scale-110 transition-all" />
              </button>

              <button
                className="primary-btn mt-6"
                onClick={searchHouse}
                disabled={
                  (housenb !== "" && postcode !== "" ? false : true) || !adress
                }
              >
                {t("button.1")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
