import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import DragCloseDrawer from "./DragCloseDrawer";
import { useScrollAnimate } from "../../../../hooks/useScrollAnimate";
import { motion } from "framer-motion";

export default function SingleCard({ single, idx }) {
  const [open, setOpen] = useState(false);
  const { ref, controls } = useScrollAnimate();

  return (
    <>
      <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.4, ease: "easeIn" }}
        ref={ref}
        className="group flex flex-col max-h-[600px] rounded-xl overflow-hidden shadow-lg z-10"
      >
        <div className="w-full h-[65%] overflow-hidden bg-green-900">
          <img
            className="w-full h-full object-contain transition-all duration-500 group-hover:scale-105"
            src={`${process.env.PUBLIC_URL}/intro/intro${idx + 1}.png`}
            alt="Sunset in the mountains"
          />
        </div>
        <div className="px-6 h-[35%] pt-4 pb-6 bg-primaryGray flex flex-col justify-between">
          <div>
            <motion.p
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{
                delay: idx / 2 + 0.4,
                duration: 0.8,
                ease: "easeIn",
              }}
              className="font-bold text-xl mb-2"
            >
              {single.title}
            </motion.p>
            <motion.p
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{
                delay: idx / 2 + 0.5,
                duration: 0.8,
                ease: "easeIn",
              }}
              className="text-gray-700 text-base"
            >
              {single.subtitle}
            </motion.p>
          </div>

          <motion.div
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0, scale: 0 },
              visible: { opacity: 1, scale: 1 },
            }}
            transition={{
              delay: idx / 2 + 0.6,
              duration: 0.6,
              ease: "easeOut",
            }}
          >
            <button
              onClick={() => setOpen(true)}
              className="mb-0 flex items-center active:scale-95 gap-x-3 transition-all hover:gap-x-6 w-[180px] shadow-jubilation hover:brightness-110 hover:shadow-none bg-primaryBlue text-white px-4 py-2 rounded-xl"
            >
              <span>{single.btn}</span> <FaArrowRight />{" "}
            </button>
          </motion.div>
        </div>
      </motion.div>

      <DragCloseDrawer open={open} setOpen={setOpen}>
        <div className="max-w-2xl mx-auto">
          <p className="text-4xl font-bold text-white">{single.title}</p>
          <p className="text-2xl font-semibold text-gray-300 mt-3 mb-5">
            {single.subtitle}
          </p>

          <div className="space-y-3 text-lg text-white font-medium">
            {single.content.split("|").map((singleDetail) => (
              <p>{singleDetail}</p>
            ))}
          </div>
        </div>
      </DragCloseDrawer>
    </>
  );
}
