import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiSolidErrorAlt } from "react-icons/bi";
import useScrollToTop from "./hooks/useScrollToTop";

export default function Cancel() {
  const { t } = useTranslation("global");
  useScrollToTop();

  return (
    <div className="flex justify-center items-center py-20">
      <div className="max-w-xl rounded-2xl shadow-jubilation bg-white px-14 py-20">
        <BiSolidErrorAlt className="text-6xl text-red-600" />

        <p className="mt-6 text-3xl font-bold text-red-600">Betaling mislukt</p>

        <p className="font-medium mt-2 ">{t("trans.2")}</p>

        <Link to="/search">
          <button className="mt-4 primary-btn !w-fit px-8">
            terug naar bouwruimte.nl
          </button>
        </Link>

        <p className="mt-4 font-semibold">
          <span className="text-gray-600">Heb je een vraag ? </span>

          <Link to="/contact">
            <span className="font-bold text-blue-600">
              Neem contact met ons op
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
}
