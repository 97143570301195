import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";
import { useEffect } from "react";

export default function DrawMissing() {
  const { t } = useTranslation("global");

  const {
    original,
    setOriginal,
    residential,
    setResidential,
    main,
    setMain,
    coordinates,
    setcoordinates,
    iid,
    setiid,
    featureList,
    setfeatureList,
    quesNb,
    setquesNb,
    setshow,
    lat,
    setlat,
    setlng,
    setdrawnow,
    drawMiss,
    setdrawMiss,
    setdrawMissSecond,
    setanotherMiss,
    isRes,
    setisRes,
    isOriginal,
    setisOriginal,
    mainBuild,
    setmainBuild,
    setshowMsgHint,
    setshowHintbox,
    setdisplayMsg,
    setdisplayMsgh1,
    continueFlow,
    setcontinueFlow,
    situation,
    setsituationTwo,
  } = useMapState();

  const anotherMissnoaskMissFirst = document.querySelectorAll(
    ".anotherMissnoaskMissFirst"
  );

  const handleStartTwo = (msg) => {
    if (msg === "yes") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.104"));
      setdisplayMsgh1(t("flow.4"));
    }
    setmainBuild(true);
    setsituationTwo(msg);
    setMain(msg);
    setcontinueFlow(false);
    if (msg === "no") {
      setdrawnow(true);
      setResidential("no");
      setOriginal("no");
      setcontinueFlow(true);
    }
  };

  const handleSetRes = (msg) => {
    setshowHintbox(true);
    setshowMsgHint(true);
    setdisplayMsg(t("flow.105"));
    setdisplayMsgh1(t("flow.5"));
    setResidential(msg);
    setisRes(true);
  };

  const handleSetOriginal = (msg, stat) => {
    setdrawnow(true);
    setOriginal(msg);
    setisOriginal(true);
    setdrawnow(true);
    if (stat === "show") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.109"));
      setdisplayMsgh1(t("flow.9"));
    }
    if (stat === "hide") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.140"));
      setdisplayMsgh1(t("flow.40"));
    }
  };

  const handleAddToList = (msg) => {
    setdrawnow(false);
    setshowHintbox(false);

    const features = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: coordinates,
      },
      properties: {
        id: iid,
        original: original === "yes" ? "ja" : "nee",
        residential: residential === "yes" ? "ja" : "nee",
        main: main === "yes" ? "ja" : "nee",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setlat("");
      setlng("");
      setResidential("");
      setMain("");
      setOriginal("");
      setcoordinates("");
      setiid(iid + 1);
    }
    if (msg === "anot") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.107"));
      setdisplayMsgh1(t("flow.7"));
      anotherMissnoaskMissFirst.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
    } else {
      setanotherMiss(true);
    }
    setquesNb(quesNb + 1);
    setshow(true);
    setmainBuild(false);
    setisRes(false);
    setisOriginal(false);
    setdrawMiss(false);
    setdrawMissSecond(true);
    if (msg === "no" || msg === "yes") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.150"));
      setdisplayMsgh1(t("flow.50"));
    }
  };

  return (
    <>
      {situation === "no" && drawMiss && (
        <>
          {!mainBuild && (
            <>
              <p className="text-lg text-primaryBlue mb-4">{t("flow.3")}</p>
              <div className="flex gap-x-2">
                <button
                  className="primary-btn !w-[140px]"
                  onClick={() => handleStartTwo("yes")}
                >
                  {t("yesno.1")}
                </button>
                <button
                  className="primary-btn !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
                  onClick={() => handleStartTwo("no")}
                >
                  {t("yesno.2")}
                </button>
              </div>
            </>
          )}

          {mainBuild && (
            <>
              {!continueFlow && (
                <>
                  <div className="text-box">
                    {!isRes && (
                      <>
                        <p className="text-primaryBlue text-lg mb-4">
                          {t("flow.4")}
                        </p>

                        <div className="flex gap-x-2 mt-4">
                          <button
                            className="primary-btn !w-[140px]"
                            onClick={() => handleSetRes("yes")}
                          >
                            {t("yesno.1")}
                          </button>
                          <button
                            className="primary-btn !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
                            onClick={() => handleSetRes("no")}
                          >
                            {t("yesno.2")}
                          </button>
                        </div>
                      </>
                    )}
                  </div>

                  {isRes && (
                    <div className="text-box">
                      {!isOriginal && (
                        <>
                          <p className="text-primaryBlue text-lg mb-4">
                            {t("flow.5")}
                          </p>
                          <div className="flex gap-x-2 mt-4">
                            <button
                              className="primary-btn !w-[140px]"
                              onClick={() => handleSetOriginal("yes", "show")}
                            >
                              {t("yesno.1")}
                            </button>
                            <button
                              className="primary-btn !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
                              onClick={() => handleSetOriginal("no", "show")}
                            >
                              {t("yesno.2")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              <DrawComp handleAddToList={handleAddToList} />
            </>
          )}
        </>
      )}
    </>
  );
}

function DrawComp({ handleAddToList }) {
  const { t } = useTranslation("global");

  const { isOriginal, continueFlow, setEnableContinue, enableContinue } =
    useMapState();

  useEffect(() => {
    setEnableContinue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(isOriginal || continueFlow) && (
        <div className="">
          <div className="text-lg text-primaryBlue">
            <p>{t("flow.9")}</p>
          </div>

          <div className="flex justify-center mt-4">
            <button
              className="primary-btn"
              disabled={!enableContinue}
              onClick={() => handleAddToList("anot")}
            >
              {t("button.3")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
