import { useTranslation } from "react-i18next";
import AnimatedTitle from "../Common/AnimatedTitle";
import RevealingFromBottom from "../Common/RevealingFromBottom";

export default function Title() {
  const { t } = useTranslation("global");

  return (
    <div className="mx-auto max-w-2xl text-center">
      <AnimatedTitle
        extraDelay={0.4}
        duration={0.5}
        className="text-2xl font-bold leading-10 tracking-tight text-gray-900"
      >
        {t("faqs.title")}
      </AnimatedTitle>

      <RevealingFromBottom
        extraDelay={0.6}
        duration={0.5}
        className="mt-6 text-base leading-7 text-gray-600"
      >
        {t("faqs.subtitle")}
      </RevealingFromBottom>
    </div>
  );
}
