import { useTranslation } from "react-i18next";
import { TiTick } from "react-icons/ti";
import { MdFileDownload } from "react-icons/md";

export default function PrivacyBox({
  handleVerifyread,
  handleDownloadPdf,
  read,
}) {
  const { t } = useTranslation("global");

  return (
    <div className="bg-white/60 rounded-xl shadow-warm max-w-3xl mx-auto relative">
      <div
        className={` ${
          !read ? "bg-gray-500" : "bg-secondaryBlue"
        } p-1 rounded-full w-fit absolute left-[50%] transition-all -translate-x-[50%] -translate-y-[50%]`}
      >
        <TiTick className="text-8xl text-white" />
      </div>

      <div className="pt-20 px-20 pb-10">
        <p className="text-3xl font-semibold text-center text-primaryBlue">
          {t("privacySection.title")}
        </p>

        <div className="flex items-start gap-x-2 mt-10">
          <input
            type="checkbox"
            id="verifyread"
            disabled={!read}
            onChange={handleVerifyread}
            className="h-4 w-4 rounded-full mt-1 accent-primaryBlue cursor-pointer"
          />

          <p className="text-lg font-medium">
            {t("privacySection.checkboxLabel")}
          </p>
        </div>

        <button
          className="text-white mt-4 shadow-warm hover:brightness-110 hover:shadow-none transition-all bg-secondaryBlue py-1 rounded-lg flex w-fit items-center px-6 gap-x-4 justify-between"
          onClick={handleDownloadPdf}
        >
          <span> {t("privacySection.downloadBtn")}</span>

          <MdFileDownload className="text-xl" />
        </button>

        <h3 className="mt-6">
          {t("privacySection.clickMessage")
            .split("")
            .map((l, idx) => (
              <span
                className={`${idx > 4 && idx < 35 && "text-secondaryBlue"}`}
              >
                {l}
              </span>
            ))}
        </h3>
      </div>
    </div>
  );
}
