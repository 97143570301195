import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";

export default function DrawDeletedBuilding() {
  const { t } = useTranslation("global");

  const {
    setEnableContinue,
    situation,
    enableContinue,
    coordinates,
    setdrawnow,
    setfeatureList,
    lat,
    setcoordinates,
    featureList,
    setshowHintbox,
    setshowMsgHint,
    setdisplayMsg,
    setdisplayMsgh1,
    setsituation,
  } = useMapState();

  useEffect(() => {
    setEnableContinue(false);
  }, [setEnableContinue]);

  const yesnodrawConfirmed = document.querySelectorAll(".yesnodrawConfirmed");
  const drawSecondLine = document.querySelectorAll(".drawSecondLine");
  const deletedBuildingText = document.querySelector(".deletedBuildingText");

  function handleDelete() {
    setdrawnow(false);

    const features = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: coordinates,
      },
      properties: {
        id: "Delete Building",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setcoordinates("");
    }

    if (situation === "yes") {
      setEnableContinue(false);

      yesnodrawConfirmed?.forEach((item) => {
        item.classList?.add("showitem");
        item.classList?.remove("hideitem");
      });
    } else {
      setEnableContinue(false);

      drawSecondLine?.forEach((item) => {
        item.classList?.add("showitem");
        item.classList?.remove("hideitem");
      });
    }

    deletedBuildingText?.classList?.remove("showitem");
    deletedBuildingText?.classList?.add("hideitem");

    setdrawnow(true);
    setshowHintbox(true);
    setshowMsgHint(true);

    setdisplayMsg(t("flow.102"));
    setdisplayMsgh1(t("flow.2"));
    setsituation("yes");
  }

  return (
    <div className="deletedBuildingText hideitem">
      <div className="text-box text-primaryBlue text-lg mb-6">
        <p>{t("flow.12")}</p>
      </div>
      <div className="flex justify-center gap-x-3">
        <button
          disabled={!enableContinue}
          className="primary-btn"
          id="addDeletedBuilding"
          onClick={handleDelete}
        >
          {/* {t("yesno.1")} */}
          Bevestig Tekening
        </button>
      </div>{" "}
    </div>
  );
}
