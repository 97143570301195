import React from "react";
import { useTranslation } from "react-i18next";
import SingleFAQ from "./SingleFAQ";
import Title from "./Title";
import useScrollToTop from "../../hooks/useScrollToTop";
export default function Question() {
  const { t } = useTranslation("global");

  const data = t("faqs.content", { returnObjects: true });

  useScrollToTop();

  return (
    <div className="min-h-screen">
      <div className="mx-auto max-w-7xl px-6 py-10 sm:py-24 lg:px-8 relative z-20">
        <Title />

        <dl className="mt-20 space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
          {data.map((faq, idx) => (
            <SingleFAQ idx={idx} faq={faq} />
          ))}
        </dl>
      </div>

      <div
        className="absolute top-0 transform-gpu overflow-hidden right-0 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="relative aspect-[1155/678] w-[36.125rem] max-w-none rotate-[30deg] bg-gradient-to-tr from-secondaryBlue to-secondaryOrange opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
    </div>
  );
}
