import React from "react";
import { useTranslation } from "react-i18next";
import FollowUs from "./FollowUs";
import Links from "./Links";

export default function Footer() {
  const { t } = useTranslation("global");

  return (
    <footer className="bg-gray-900 relative z-30 md:block hidden">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="grid gap-24 grid-cols-3">
            <Links />

            <FollowUs />
          </div>
        </div>

        <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />

        {/* <div className="flex justify-center items-center gap-x-6">
          <h3 className="text-lg text-white">{t("footer.4")} </h3>
          <a href="/" target="_blank">
            <img
              src={`${process.env.PUBLIC_URL}/footer/equis.png`}
              className="h-[50px]"
              alt="equis"
            />
          </a>
        </div> */}
      </div>
    </footer>
  );
}
