import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { BiSolidErrorAlt } from "react-icons/bi";
import useScrollToTop from "./hooks/useScrollToTop";

export default function Success() {
  const { t } = useTranslation("global");
  useScrollToTop();

  return (
    <div className="flex justify-center items-center py-20">
      <div className="max-w-xl rounded-2xl shadow-jubilation bg-white px-14 py-20">
        <div className="relative w-fit">
          <BiSolidErrorAlt className="text-6xl text-primaryBlue" />

          <TiTick className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-primaryBlue text-white text-5xl rounded-full" />
        </div>

        <p className="mt-6 text-3xl font-bold text-primaryBlue">
          betaling gelukt
        </p>

        <p className="font-medium mt-2 ">{t("trans.1")}</p>

        <Link to={process.env.PUBLIC_URL}>
          <button className="mt-4 primary-btn !w-fit px-8">
            ga terug naar bouwruimte.nl
          </button>
        </Link>
      </div>
    </div>
    // <div>
    //   <p
    //     style={{
    //       color: "#d4c44c",
    //       textAlign: "left",
    //       fontSize: "2vw",
    //       marginTop: "30px",
    //     }}
    //   >
    //     <span style={{ margin: "10px 10px 0 0" }}>
    //       <img src={require("./images/fireworks.png")} width={30} height={30} />
    //     </span>
    //     {t("trans.1")}
    //     <span style={{ margin: "10px 0 0 10px" }}>
    //       <img src={require("./images/fireworks.png")} width={30} height={30} />
    //     </span>
    //   </p>
    //   {/* <h1 className='h1'> please click on the button to get your pdf</h1> */}
    //   <Link to={process.env.PUBLIC_URL}>
    //     <button className="btn-succ">ga terug naar bouwruimte.nl</button>
    //   </Link>
    // </div>
  );
}
