import React from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";
import InputComp from "../../Common/InputComp";

export default function SearchforHouse() {
  const { t } = useTranslation("global");

  const {
    houseltr,
    sethouseltr,
    huisnummertoevoeging,
    sethuisnummertoevoeging,
    email,
    setemail,
    postcode,
    setPostcode,
    housenb,
    setHousenb,
    error,
    setError,
    setstartsarch,
    setlatrd,
    setlngrd,
    setadress,
    setshowSituation,
    searchforHouse,
  } = useMapState();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const alertSure = () => {
    setshowSituation(true);
    setstartsarch(true);
    const houseurl = `https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=${postcode}&start=0&rows=1000&fl=*`;

    fetch(houseurl)
      .then((res) => res.json())
      .then((datahouse) => {
        let hs = datahouse["response"]["docs"].filter(
          (item) => item.huisnummer === Number(housenb)
        );

        if (hs.length > 1) {
          const hslt = hs;
          hs = hs.filter((item) => item.huisletter === houseltr);
          if (hs.length === 0) {
            hs = hslt.filter(
              (item) => item.huisnummertoevoeging === huisnummertoevoeging
            );
            if (hs.length === 0) {
              hs = hslt[0];
            }
          } else if (hs.length > 1) {
            hs = hs.filter(
              (item) => item.huisnummertoevoeging === huisnummertoevoeging
            );
          }
        }

        if (hs[0] && hs[0]["weergavenaam"]) {
          setadress(hs[0]["weergavenaam"]);
        } else if (hs["weergavenaam"]) {
          setadress(hs["weergavenaam"]);
        }
        if (hs[0] && hs[0]["huisletter"]) {
          sethouseltr(hs[0]["huisletter"]);
        }

        let inputString;

        if (hs[0] && hs[0]["geometrie_rd"]) {
          inputString = hs[0]["geometrie_rd"];
        } else if (hs["geometrie_rd"]) {
          inputString = hs["geometrie_rd"];
        }

        let splitNumbers;

        if (inputString.match(/\d+\.\d+/g)) {
          const numbersOnly = inputString.match(/\d+\.\d+/g); // Extract numbers with decimals

          splitNumbers = numbersOnly.map((number) => number.split(" "));
        } else if (inputString.match(/\d+/g)) {
          const numbersOnly = inputString.match(/\d+/g); // Extract numbers with decimals

          splitNumbers = numbersOnly.map((number) => number.split(" "));
        }
        if (splitNumbers.length < 2) {
          const numbersOnly = [];
          const onlyDigitsArray = inputString.match(/\d+/g);
          const withDec = inputString.match(/\d+\.\d+/g);
          let onlyDigits;

          if (onlyDigitsArray[0] + "." + onlyDigitsArray[1] === withDec) {
            onlyDigits = onlyDigitsArray[2];
          } else if (
            onlyDigitsArray[1] + "." + onlyDigitsArray[2] ===
            withDec
          ) {
            onlyDigits = onlyDigitsArray[0];
          }

          if (parseFloat(onlyDigitsArray) > parseFloat(withDec)) {
            numbersOnly.push(withDec);
            numbersOnly.push(onlyDigits);
          } else {
            numbersOnly.push(onlyDigits);
            numbersOnly.push(withDec);
          }
          splitNumbers = numbersOnly;
        }

        if (parseFloat(splitNumbers[1]) > parseFloat(splitNumbers[0])) {
          setlatrd(parseFloat(splitNumbers[0]));
          setlngrd(parseFloat(splitNumbers[1]));
        } else {
          setlatrd(parseFloat(splitNumbers[1]));
          setlngrd(parseFloat(splitNumbers[0]));
        }
      });
  };

  const handleChangeEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("e-mail is ongeldig");
    } else {
      setError(null);
    }

    setemail(event.target.value);
  };

  return (
    <>
      {searchforHouse && (
        <div>
          <p className="text-2xl font-semibold text-gray-600 mb-6">
            Zoek uw huis
          </p>

          <div className="space-y-4 w-[550px]">
            <div className="relative">
              <InputComp
                label={t("questions.4")}
                name="email"
                placeholder="email"
                value={email}
                onChange={handleChangeEmail}
              />

              {error && (
                <div className="absolute right-0 -bottom-4">
                  <h2 className="text-xs font-semibold text-red-500">
                    Ongeldige e-mail
                  </h2>
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <InputComp
                label={t("questions.12")}
                name="postcode"
                placeholder="1234AB"
                value={postcode}
                maxLength={6}
                onChange={(e) => {
                  setPostcode(e.target.value);
                }}
              />

              <InputComp
                label={t("questions.13")}
                name="houseNb"
                placeholder="22"
                maxLength={4}
                value={housenb}
                onChange={(e) => {
                  setHousenb(e.target.value);
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <InputComp
                label={t("questions.14")}
                name="houseLtr"
                placeholder="_"
                maxLength={1}
                value={houseltr}
                onChange={(e) => {
                  sethouseltr(e.target.value);
                }}
              />

              <InputComp
                label={t("questions.15")}
                name="huisnummertoevoeging"
                placeholder="_"
                maxLength={4}
                value={huisnummertoevoeging}
                onChange={(e) => {
                  sethuisnummertoevoeging(e.target.value);
                }}
              />
            </div>

            <button
              className="primary-btn !mt-6"
              onClick={alertSure}
              disabled={
                (postcode !== ""
                  ? housenb !== 0
                    ? email !== ""
                      ? false
                      : true
                    : true
                  : true) || error
              }
            >
              {t("button.5")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
