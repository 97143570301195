export function getRandomColor(x) {
  var colors = [
    "rgba(0,255,0,1)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
    "rgba(255,0,0,0.3)",
  ];
  return colors[x];
}
