import DrawLineConfirmed from "./DrawLineConfirmed";
import DrawMissing from "./DrawMissing";
import AskForNewConstruction from "./AskForNewConstruction";
import DrawLineBuilding from "./DrawLineBuilding";
import DrawConfirmedyes from "./DrawConfirmedyes";
import MessageBuildingDeleted from "./MessageBuildingDeleted";
import DrawDeletedBuilding from "./DrawDeletedBuilding";

export default function All({ sendReq }) {
  return (
    <>
      <div className="allitems hideitem">
        <div className="start">
          <div className="questContainer">
            <DrawMissing />

            <AskForNewConstruction />

            <DrawLineBuilding sendReq={sendReq} />

            <DrawLineConfirmed sendReq={sendReq} />

            <DrawConfirmedyes sendReq={sendReq} />

            <MessageBuildingDeleted />

            <DrawDeletedBuilding />
          </div>
        </div>
      </div>
    </>
  );
}
