import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";

export default function AskSituation() {
  const { t } = useTranslation("global");

  const { setEnableContinue } = useMapState();

  return (
    <div className="showSituationclass hideitem questContainer">
      <div className="text-box text-primaryBlue text-lg mb-6">
        <p>{t("flow.1")}</p>
      </div>
      <div className="flex gap-x-2">
        <button className="primary-btn !w-[140px]" id="sityes">
          {t("yesno.1")}
        </button>

        <button
          className="primary-btn !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
          id="sitnostart"
          onClick={() => setEnableContinue(false)}
        >
          {t("yesno.2")}
        </button>
      </div>
    </div>
  );
}
