import { useTranslation } from "react-i18next";
import useMapState from "../../../../helpers/mapState";
import { useEffect } from "react";
import { TiTick } from "react-icons/ti";

export default function DrawSecondLine() {
  const showDrawHouse = document.querySelectorAll(".showDrawHouse");
  const nostarttwo = document.querySelectorAll(".nostarttwo");
  const yesnodrawConfirmed = document.querySelectorAll(".yesnodrawConfirmed");
  const drawConfirmedyes = document.querySelectorAll(".drawConfirmedyes");
  const starttwo = document.querySelectorAll(".starttwo");
  const drawSecondLine = document.querySelectorAll(".drawSecondLine");
  const nodrawLine = document.querySelectorAll(".nodrawLine");
  const nodrawBuilding = document.querySelectorAll(".nodrawBuilding");
  const nostartthree = document.querySelectorAll(".nostartthree");
  const showdrawHouseSecond = document.querySelectorAll(".showdrawHouseSecond");

  const {
    coordinates,
    setcoordinates,
    featureList,
    setfeatureList,
    lat,
    setdrawnow,
    setdrawMiss,
    setdrawMissSecond,
    setanotherMiss,
    setdrawConfirmed,
    setdrawLine,
    setshowHintbox,
    setdisplayMsg,
    setdisplayMsgh1,
    setEnableContinue,
    enableContinue,
  } = useMapState();

  const { t } = useTranslation("global");

  useEffect(() => {
    setEnableContinue(false);
  }, []);

  const handleAddLineToList = (msg) => {
    setdrawnow(false);
    setshowHintbox(false);

    const features = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
      properties: {
        id: "Voorgevelrooilijn",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setcoordinates("");
    }

    setanotherMiss(false);
    setdrawConfirmed(true);
    setdisplayMsg(t("flow.106"));
    setdisplayMsgh1(t("flow.6"));

    if (msg === "fst") {
      drawConfirmedyes.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      yesnodrawConfirmed.forEach((item) => {
        item.classList.remove("showitem");
        item.classList.add("hideitem");
      });
      showDrawHouse.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      nostarttwo.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      starttwo.forEach((item) => {
        item.classList.remove("showitem");
        item.classList.add("hideitem");
      });
    } else {
      nodrawBuilding.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      drawSecondLine.forEach((item) => {
        item.classList.remove("showitem");
        item.classList.add("hideitem");
      });
      nodrawLine.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      showdrawHouseSecond.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
      nostartthree.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
    }

    setdrawLine(false);
    setdrawMiss(false);
    setdrawMissSecond(false);
  };

  return (
    <>
      <div className="drawSecondLine">
        <p className="text-box font-medium">{t("flow.2")}</p>

        <button
          className="flex items-center gap-x-2 primary-btn !w-fit px-4 mx-auto my-5"
          disabled={!enableContinue}
          onClick={() => handleAddLineToList("scd")}
        >
          <span>{t("button.3")}</span>
          <TiTick className="text-xl" />
        </button>
      </div>
    </>
  );
}
