import { useState, useEffect } from "react";
import Intro from "./components/Home/Intro";
import HowItWorks from "./components/Home/HowItWorks";
import GoodToKnow from "./components/Home/GoodToKnow";
import Downloads from "./components/Home/Downloads";
import Warnings from "./components/Home/Warnings";
import useScrollToTop from "./hooks/useScrollToTop";
import useMapState from "./components/helpers/mapState";

export default function App() {
  const [isSafari, setIsSafari] = useState(false);
  const resetState = useMapState((state) => state.reset);

  useScrollToTop();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    // const isSafari =
    //   /safari/.test(userAgent) && !/chrome|crios|android/.test(userAgent);

    const isSafari =
      /^((?!chrome|crios|android|firefox|fxios|edg|opera|opr).)*safari/i.test(
        userAgent
      );

    setIsSafari(isSafari);
  }, []);

  useEffect(() => {
    // Reset the state when the component is mounted (or when a specific condition is met)
    resetState();
  }, [resetState]);

  return (
    <div className="!bg-primaryGray w-[screen] overflow-hidden">
      {isSafari && (
        <div className="safari-cont">
          <div className="popup-safari">
            <h2>
              deze website werkt helaas niet goed met de Safari-browser. Gebruik
              a.u.b. een andere browser
            </h2>
          </div>
        </div>
      )}

      <Intro />

      <HowItWorks />

      <GoodToKnow />

      <Downloads />

      <Warnings />
    </div>
  );
}
