import SearchforHouse from "./SearchforHouse";
import Startsarch from "./Startsarch";
import AdjPlots from "./AdjPlots";
import AskSituation from "./AskSituation";
import useMapState from "../../../helpers/mapState";

export default function PlaceConfirmed({ searchHouse }) {
  const { placeConfirmed } = useMapState();

  return (
    <>
      {placeConfirmed && (
        <>
          <SearchforHouse />

          <Startsarch searchHouse={searchHouse} />

          <AdjPlots />

          <AskSituation />
        </>
      )}
    </>
  );
}
