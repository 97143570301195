import React from "react";

export default function InputComp({
  label,
  name,
  placeholder,
  value,
  onChange,
  maxLength,
}) {
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900 -mb-0.5 ml-1"
      >
        {label}
      </label>

      <input
        type="text"
        placeholder={placeholder}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        className="block w-full rounded-lg pl-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
      />
    </div>
  );
}
