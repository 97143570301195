import JSZip from "jszip";
import { saveAs } from "file-saver";

export const handleDownloadClick = () => {
  let filename = "Voorbeeld_Bouwruimte-resultaat";
  const files = [
    `${process.env.PUBLIC_URL}/downloads/Voorbeeld_Bouwruimte-resultaat/Algemene_voorwaarden_Bouwruimte.pdf`,
    `${process.env.PUBLIC_URL}/downloads/Voorbeeld_Bouwruimte-resultaat/Bouwruimte_1906BX_39.pdf`,
    `${process.env.PUBLIC_URL}/downloads/Voorbeeld_Bouwruimte-resultaat/instructie.txt`,
    `${process.env.PUBLIC_URL}/downloads/Voorbeeld_Bouwruimte-resultaat/legendaverklaring.pdf`,
    `${process.env.PUBLIC_URL}/downloads/Voorbeeld_Bouwruimte-resultaat/bouwruimte_1906BX_39.kmz`,
  ];
  const zip = new JSZip();
  const folder = zip.folder("project");
  files.forEach((url) => {
    const blobPromise = fetch(url).then(function (response) {
      if (response.status === 200 || response.status === 0) {
        return Promise.resolve(response.blob());
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    });
    const name = url.substring(url.lastIndexOf("/"));
    folder.file(name, blobPromise);
  });
  zip
    .generateAsync({ type: "blob" })
    .then((blob) => saveAs(blob, filename))
    .catch((e) => console.error(e));
};
