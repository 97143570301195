import { useTranslation } from "react-i18next";
import useMapState from "../helpers/mapState";
import Titles from "./Titles";
import Loader from "./Loader";

export default function Pay({ mapRef }) {
  const { pay, showdrawtext } = useMapState();
  const { t } = useTranslation("global");

  return (
    <div className="relative w-full h-[650px] col-span-3 pt-4">
      <Titles />

      <Loader />

      {!pay && (
        <>
          <div id="map" className="w-full h-[80%] mt-4" ref={mapRef}></div>

          {showdrawtext && (
            <>
              <div>©OpenStreetMap, ©AND, 2007</div>
            </>
          )}

          <div className="absolute top-16 right-8">
            <button
              className={`primary-btn !w-fit !px-5 mt-6 mb-10 transition-all ${
                showdrawtext ? "opacity-100" : "opacity-0"
              }`}
              id="undo"
            >
              {t("button.6")}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
