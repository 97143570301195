import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleClickScroll } from "../../helpers/handleClickScroll";
import ContactBG from "./ContactBG";
import { useLocation } from "react-router-dom";
import { easeInOut, motion } from "framer-motion";

export default function Header() {
  const { t } = useTranslation("global");
  const location = useLocation();

  return (
    <nav className="md:flex hidden relative z-30 w-screen justify-between bg-primaryGray shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
      <div className="flex items-center gap-x-10 pl-5 xl:pl-20">
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, easings: easeInOut, delay: 0.3 }}
        >
          <Link to={process.env.PUBLIC_URL}>
            <img
              src={`${process.env.PUBLIC_URL}/header/BouwruimteNL_logo.png`}
              alt="logo"
              className="h-[60px] aspec-auto"
            />
          </Link>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, easings: easeInOut, delay: 0.3 }}
          className="flex items-center text-primaryBlue font-semibold gap-x-3 lg:gap-x-6"
        >
          {location.pathname === process.env.PUBLIC_URL && (
            <>
              <p
                className="cursor-pointer group"
                onClick={() => handleClickScroll("howworks")}
              >
                {t("nav.wedo")}
                <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
              </p>

              <p
                className="cursor-pointer group"
                onClick={() => handleClickScroll("about")}
              >
                {t("nav.about")}
                <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
              </p>
            </>
          )}

          {location.pathname !== process.env.PUBLIC_URL && (
            <>
              <div>
                <Link className="group" to={process.env.PUBLIC_URL}>
                  <span> {t("nav.wedo")}</span>
                  <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
                </Link>
              </div>

              <div>
                <Link className="group" to={process.env.PUBLIC_URL}>
                  <span> {t("nav.about")}</span>
                  <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
                </Link>
              </div>
            </>
          )}

          <div>
            <Link className="group" to="/disclaimer">
              <span>{t("nav.disclaimer")}</span>
              {location.pathname === "/disclaimer" ? (
                <div className="bg-secondaryOrange h-[2px] w-full"></div>
              ) : (
                <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
              )}
            </Link>
          </div>

          <div>
            <Link className="group" to="/question">
              <span>{t("nav.faqs")}</span>
              {location.pathname === "/question" ? (
                <div className="bg-secondaryOrange h-[2px] w-full"></div>
              ) : (
                <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
              )}
            </Link>
          </div>
        </motion.div>
      </div>

      <div className="flex items-center relative -mr-20 xl:-mr-0">
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, easings: easeInOut, delay: 0.9 }}
          style={{ right: "50px", position: "absolute" }}
          className="hidden xl:block"
        >
          <Link to="/contact">
            <p className="px-9 py-2 rounded-full text-white font-semibold bg-primaryBlue shadow-jubilation hover:shadow-none hover:brightness-125 transition-all">
              Contact
            </p>
          </Link>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, easings: easeInOut, delay: 0.9 }}
          style={{ right: "100px", position: "absolute" }}
          className="xl:hidden block"
        >
          <Link to="/contact">
            <p className="px-7 xl:px-9 py-2 rounded-full text-white font-semibold bg-primaryBlue shadow-jubilation hover:shadow-none hover:brightness-125 transition-all">
              <span>{t("nav.contact")}</span>
            </p>
          </Link>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, easings: easeInOut, delay: 0.4 }}
        >
          <ContactBG />
        </motion.div>
      </div>
    </nav>
  );
}
