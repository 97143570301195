export const handleClickScroll = (id) => {
  const element = document.getElementById(id);
  const checkbox = document.getElementById("checkbox");

  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  if (checkbox) {
    checkbox.checked = false;
  } else {
    console.error("Checkbox element not found!");
  }
};
