import React from "react";
import { FaLinkedin } from "react-icons/fa";

export default function FollowUs() {
  return (
    <div>
      <h2 className="mb-6 text-sm font-semibold uppercase text-white">
        Follow us
      </h2>
      <div className="text-gray-400 font-medium">
        <p>
          <a
            href="https://www.linkedin.com/company/bouwruimte-nl/posts/?feedView=all"
            target="_blank"
            rel="noreferrer"
            className="hover:underline flex items-center gap-x-2"
          >
            <span>Linkedin</span>
            <FaLinkedin className="text-xl" />
          </a>
        </p>
      </div>
    </div>
  );
}
