import React from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../helpers/mapState";

export default function Pay() {
  const { t } = useTranslation("global");

  const { name, setname, pay } = useMapState();

  return (
    <>
      {pay && (
        <div className="p-5 rounded-xl shadow-jubilation">
          <div className="text-box text-primaryBlue text-lg">
            <p>{t("pay.2")}</p>
          </div>

          <div className="flex mt-4">
            <input
              className="block w-[300px] rounded-lg pl-2 border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
              id="name"
              value={name}
              type="text"
              placeholder="name"
              onChange={(e) => {
                setname(e.target.value);
              }}
            />
            <span className="text-red-500">*</span>
          </div>
        </div>
      )}
    </>
  );
}
