import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="">
      <div className="grid grid-cols-2 px-40 mx-auto py-20">
        <div className="my-auto">
          <p className="text-6xl font-bold text-secondaryGray mb-2">Ooops...</p>
          <p className="text-3xl text-primaryBlue font-semibold mb-6">
            Pagina niet gevonden
          </p>
          <p className="font-medium text-gray-600 mb-10">
            De pagina waarnaar u zoekt bestaat niet of er is een andere fout{" "}
            <br />
            opgetreden opgetreden, ga terug naar de startpagina
          </p>

          <Link to={process.env.PUBLIC_URL}>
            <p className="bg-primaryBlue text-white w-fit rounded-lg shadow-jubilation px-8 py-2 text-xl hover:shadow-none hover:brightness-105 hover:scale-95 transition-all">
              Startpagina
            </p>
          </Link>
        </div>

        <img
          className="h-[500px] aspect-auto object-cover"
          src={`${process.env.PUBLIC_URL}/notFound.png`}
          alt=""
        />
      </div>
    </div>
  );
}
