import { useTranslation } from "react-i18next";

export default function MessageBuildingDeleted() {
  const { t } = useTranslation("global");

  return (
    <div className="messageBuildingDeleted hideitem">
      <div className="text-box text-lg text-primaryBlue">
        <p>{t("flow.11")}</p>
      </div>
      <div className="flex gap-x-2 mt-4">
        <button className="primary-btn !w-[140px]" id="drawDeletedBuilding">
          {t("yesno.1")}
        </button>
        <button
          className="primary-btn !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
          id="dontDrawDeletedBuilding"
        >
          {t("yesno.2")}
        </button>
      </div>
    </div>
  );
}
