import React from "react";

export default function MobileWarning() {
  return (
    <div className="block md:hidden h-screen w-screen bg-primaryGray pt-7 px-4">
      <img
        src={`${process.env.PUBLIC_URL}/header/BouwruimteNL_logo.png`}
        alt="logo"
        className="h-[60px] aspect-auto mx-auto"
      />

      <div className="flex flex-col items-center justify-center text-center mt-10">
        <p className="text-5xl font-semibold ">Let op</p>
        <p className="text-2xl font-medium mt-5">
          deze website is momenteel alleen geoptimaliseerd voor laptop- en
          desktopschermen en werkt mogelijk niet correct op mobiele apparaten.
        </p>

        <img
          src={`${process.env.PUBLIC_URL}/noPhone.svg`}
          className="h-[200px] mt-5"
          alt=""
        />

        <p className="text-2xl mt-5">
          We raden u aan een laptop of desktopcomputer te gebruiken om toegang
          te krijgen tot alle functies
        </p>
      </div>
    </div>
  );
}
