import React from "react";
import { handleClickScroll } from "../../../helpers/handleClickScroll";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export default function Links() {
  const { t } = useTranslation("global");
  const location = useLocation();

  return (
    <div>
      <h2 className="mb-6 text-sm font-semibold text-white uppercase s">
        Links
      </h2>
      <ul className="text-gray-400 font-medium flex flex-col space-y-3">
        {location.pathname === process.env.PUBLIC_URL && (
          <>
            <p
              className="cursor-pointer"
              onClick={() => handleClickScroll("howworks")}
            >
              {t("nav.wedo")}
            </p>

            <p
              className="cursor-pointer"
              onClick={() => handleClickScroll("about")}
            >
              {t("nav.about")}
            </p>
          </>
        )}

        {location.pathname !== process.env.PUBLIC_URL && (
          <>
            <Link to={process.env.PUBLIC_URL}>{t("nav.wedo")}</Link>

            <Link to={process.env.PUBLIC_URL}>{t("nav.about")}</Link>
          </>
        )}

        <Link to="/disclaimer">Disclaimer</Link>

        <Link to="/question">FAQs</Link>
      </ul>
    </div>
  );
}
