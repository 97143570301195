import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";

export function createVectors(geojsonFormat, dutchProjuction, data, features2) {
  const vectorSource2 = new VectorSource({
    features: features2,
  });

  const features = geojsonFormat.readFeatures(data, {
    featureProjection: dutchProjuction,
  });

  const vectorSource = new VectorSource({
    features: features,
  });

  const drawVectorPoly = new VectorLayer({
    source: new VectorSource(),
  });

  const drawVectorLine = new VectorLayer({
    source: new VectorSource(),
    style: {
      "stroke-color": "rgba(0, 0, 255, 1)",
      "stroke-width": 8,
      "fill-color": "rgba(0, 0, 255, 0)",
    },
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      stroke: new Stroke({
        color: "rgba(100,180,140,0.4)",
        width: 1,
      }),
    }),
  });

  const vectorLayerbgt = new VectorLayer({
    source: vectorSource2,
    style: new Style({
      stroke: new Stroke({
        color: "rgba(100,180,140,0.4)",
        width: 2,
      }),
    }),
  });
  const vector = new VectorSource({ wrapX: false });

  const drawVector = new VectorLayer({
    source: vector,
  });

  return {
    vectorSource2,
    features,
    vectorSource,
    drawVectorPoly,
    drawVectorLine,
    vectorLayer,
    vectorLayerbgt,
    vector,
    drawVector,
  };
}
