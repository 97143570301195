import { useTranslation } from "react-i18next";
import { TiTick } from "react-icons/ti";
import useMapState from "../../../../helpers/mapState";
import DrawSecondLine from "./DrawSecondLine";
import DrawComp from "./DrawComp";

export default function DrawLineBuilding({ sendReq }) {
  const { t } = useTranslation("global");

  const {
    original,
    setOriginal,
    residential,
    setResidential,
    main,
    setMain,
    setpay,
    coordinates,
    setcoordinates,
    iid,
    setiid,
    featureList,
    setfeatureList,
    quesNb,
    setquesNb,
    setshow,
    lat,
    setlat,
    setlng,
    setdrawnow,
    setdrawMiss,
    drawMissSecond,
    setdrawMissSecond,
    anotherMiss,
    setanotherMiss,
    isRes,
    setisRes,
    isOriginal,
    setisOriginal,
    mainBuild,
    setmainBuild,
    finished,
    startthree,
    setaskMissFirst,
    setshowMsgHint,
    setshowHintbox,
    setdisplayMsg,
    setdisplayMsgh1,
    resMiss,
    setresMiss,
    continueFlow,
    setcontinueFlow,
    situation,
    setsituation,
    reannovSecond,
    setreannovSecond,
  } = useMapState();

  const anotherMissnoaskMissFirst = document.querySelectorAll(
    ".anotherMissnoaskMissFirst"
  );
  const showdrawHouseSecond = document.querySelectorAll(".showdrawHouseSecond");
  const undo = document.getElementById("undo");

  const handleReannovSecond = (msg) => {
    setreannovSecond(msg);
    undo?.classList.add("hideitem");
    undo?.classList.remove("showitem");
    setpay(true);
    showdrawHouseSecond.forEach((item) => {
      item.classList.remove("showitem");
      item.classList.add("hideitem");
    });
  };

  const handleAddBuildingToListSecond = () => {
    setdrawnow(false);
    setshowHintbox(false);
    const features = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: coordinates,
      },
      properties: {
        id: "Oorspronkelijk hoofdgebouw",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setcoordinates("");
    }
    showdrawHouseSecond.forEach((item) => {
      item.classList.remove("showitem");
      item.classList.add("hideitem");
    });
    // setshowdrawHouseSecond(false)
    setmainBuild(false);
    setisRes(false);
    setisOriginal(false);
    setdrawMiss(false);
    setaskMissFirst(false);
    setresMiss(true);
    setshowHintbox(true);
    setshowMsgHint(true);
    setdisplayMsg(t("flow.110"));
    setdisplayMsgh1(t("flow.10"));
  };

  const resMissFound = (msg, sit) => {
    setresMiss(false);
    setshowHintbox(false);

    if (msg === "yes") {
      // Show {questions.1} when msg is "yes"
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.170"));
      setdisplayMsgh1(t("questions.1"));
      setcontinueFlow(false);
      setdrawMissSecond(true);
      setmainBuild(true);
      setMain("yes");
      setisRes(true);
      setResidential("yes");
    } else {
      // Handle case when msg is "no"
      if (sit === "yes") {
        setpay(true);
        undo?.classList.add("hideitem");
        undo?.classList.remove("showitem");
      } else if (sit === "no") {
        setpay(true);
        undo?.classList.add("hideitem");
        undo?.classList.remove("showitem");
      }
    }
  };

  const handleSetOriginal = (msg, stat) => {
    setdrawnow(true);
    setOriginal(msg);
    setisOriginal(true);
    setdrawnow(true);
    if (stat === "show") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.109"));
      setdisplayMsgh1(t("flow.9"));
    }
    if (stat === "hide") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.140"));
      setdisplayMsgh1(t("flow.40"));
    }
  };

  const handleAddToList = (msg) => {
    setdrawnow(false);
    setshowHintbox(false);

    const features = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: coordinates,
      },
      properties: {
        id: iid,
        original: original === "yes" ? "ja" : "nee",
        residential: residential === "yes" ? "ja" : "nee",
        main: main === "yes" ? "ja" : "nee",
      },
    };

    if (lat.trim() !== "dzc") {
      setfeatureList([...featureList, features]);
      setlat("");
      setlng("");
      setResidential("");
      setMain("");
      setOriginal("");
      setcoordinates("");
      setiid(iid + 1);
    }
    if (msg === "anot") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.107"));
      setdisplayMsgh1(t("flow.7"));
      anotherMissnoaskMissFirst.forEach((item) => {
        item.classList.add("showitem");
        item.classList.remove("hideitem");
      });
    } else {
      setanotherMiss(true);
    }
    setquesNb(quesNb + 1);
    setshow(true);
    setmainBuild(false);
    setisRes(false);
    setisOriginal(false);
    setdrawMiss(false);
    setdrawMissSecond(true);
    if (msg === "no" || msg === "yes") {
      setshowHintbox(true);
      setshowMsgHint(true);
      setdisplayMsg(t("flow.150"));
      setdisplayMsgh1(t("flow.50"));
    }
  };

  const handleContinueDrawMissingLast = (msg) => {
    if (msg === "yes") {
      setanotherMiss(false);
      setcontinueFlow(false);
      setdrawMissSecond(true);
      setmainBuild(true);
      setMain("yes");
      setisRes(true);
      setResidential("yes");
    } else if (msg === "no") {
      setsituation("j");
      setpay(true);
      undo?.classList.add("hideitem");
      undo?.classList.remove("showitem");
    }
  };

  return (
    <div className="nodrawBuilding">
      <DrawSecondLine />

      {/* draw building */}
      <div className="nodrawLine">
        <div className="showdrawHouseSecond">
          <div className="text-box">
            <p className="font-medium">{t("flow.6")}</p>
          </div>

          <div className="nostartthree flex gap-x-2 my-5">
            <button className="!w-[140px] primary-btn" id="handleReannovSecond">
              {t("yesno.1")}
            </button>
            <button
              className="!w-[140px] primary-btn !text-primaryBlue !bg-white !border-[3px] !border-primaryBlue"
              onClick={() => handleReannovSecond("no")}
            >
              {t("yesno.2")}
            </button>
          </div>

          <div className="startthreeyes">
            <div className="text-box font-medium">
              <p>{t("flow.8")}</p>
            </div>
            <button
              className="flex items-center gap-x-2 primary-btn !w-fit px-4 mx-auto my-5"
              onClick={handleAddBuildingToListSecond}
            >
              <span>{t("button.3")}</span>
              <TiTick className="text-xl" />
            </button>
          </div>
        </div>

        {/* draw miss construction */}
        {resMiss && situation === "no" && (
          <>
            <div className="text-lg text-primaryBlue">
              <p>{t("flow.10")}</p>
            </div>

            <div className="flex gap-x-2 mt-4">
              <button
                className="primary-btn !w-[140px]"
                onClick={() => resMissFound("yes", "no")}
              >
                {t("yesno.1")}
              </button>
              <button
                className="primary-btn !w-[140px] !text-primaryBlue !bg-white !border-[3px] !border-primaryBlue"
                onClick={() => resMissFound("no", "no")}
              >
                {t("yesno.2")}
              </button>
            </div>
          </>
        )}

        {drawMissSecond && (
          <div>
            <div>
              {mainBuild && (
                <>
                  {!continueFlow && (
                    <>
                      {isRes && (
                        <div className="text-box">
                          <p className="text-box text-lg text-primaryBlue">
                            {t("questions.1")}
                          </p>
                          {!isOriginal && (
                            <div className="flex gap-x-2 mt-4">
                              <button
                                className="primary-btn !w-[140px]"
                                onClick={() => handleSetOriginal("yes", "hide")}
                              >
                                {t("yesno.1")}
                              </button>
                              <button
                                className="primary-btn !w-[140px] !text-primaryBlue !bg-white !border-[3px] !border-primaryBlue"
                                onClick={() => handleSetOriginal("no", "hide")}
                              >
                                {t("yesno.2")}
                              </button>
                            </div>
                          )}
                          {isOriginal && <p>{t(original)}</p>}
                        </div>
                      )}
                    </>
                  )}

                  <DrawComp handleAddToList={handleAddToList} />
                </>
              )}
            </div>

            {anotherMiss && situation === "no" && (
              <>
                <div className="text-box">
                  <p className="text-box text-lg text-primaryBlue">
                    {t("flow.50")}
                  </p>
                </div>
                <div className="btn-row1">
                  <button
                    className="primary-btn !w-[140px]"
                    onClick={() => handleContinueDrawMissingLast("yes")}
                  >
                    {t("yesno.1")}
                  </button>
                  <button
                    className="primary-btn !w-[140px] border-[3px] border-primaryBlue !bg-white !text-primaryBlue"
                    onClick={() => handleContinueDrawMissingLast("no")}
                  >
                    {t("yesno.2")}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {((startthree && reannovSecond === "no") || finished) && (
        <>
          <button className="btn" id="btn" onClick={sendReq}>
            {t("button.4")}
          </button>
        </>
      )}
    </div>
  );
}
