import AnimatedTitle from "./components/Common/AnimatedTitle";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useScrollAnimate } from "./hooks/useScrollAnimate";
import useScrollToTop from "./hooks/useScrollToTop";

export default function Contact() {
  const { t } = useTranslation("global");
  const { ref, controls } = useScrollAnimate();

  useScrollToTop();

  return (
    <div className="">
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3 py-20 px-5 lg:px-14 xl:px-32 relative z-10">
        <div className="relative z-10">
          <AnimatedTitle
            extraDelay={0.3}
            className="text-3xl font-bold text-gray-900"
          >
            {t("contact.title")}
          </AnimatedTitle>

          <AnimatedTitle className="mt-4 leading-7 text-gray-600">
            {t("contact.subTitle")}
          </AnimatedTitle>
        </div>

        <div
          ref={ref}
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8 relative z-10"
        >
          {/* Vestigingsadres  */}
          <motion.div
            initial="hidden"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            animate={controls}
            transition={{
              duration: 0.6,
              ease: "easeIn",
              delay: 0.5,
            }}
            className="rounded-2xl bg-primaryBlue p-10"
          >
            <h3 className="text-lg font-semibold leading-7 text-white">
              {t("contact.businessAddress.title")}
            </h3>
            <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-200">
              <p>{t("contact.businessAddress.content1")}</p>
              <p className="mt-1">{t("contact.businessAddress.content2")}</p>
            </dl>
          </motion.div>

          {/* Telefoon */}
          <motion.div
            initial="hidden"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            animate={controls}
            transition={{
              duration: 0.6,
              ease: "easeIn",
              delay: 0.6,
            }}
            className="rounded-2xl bg-primaryBlue p-10"
          >
            <h3 className="text-lg font-semibold leading-7 text-white">
              {t("contact.telefoon.title")}
            </h3>
            <a href="tel:0610503192">
              <p className="font-semibold mt-3 bg-white w-fit px-4 py-2 text-primaryBlue rounded-xl hover:scale-105 transition-all text-sm">
                {t("contact.telefoon.number")}
              </p>
            </a>
          </motion.div>

          {/* Email */}
          <motion.div
            initial="hidden"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            animate={controls}
            transition={{
              duration: 0.6,
              ease: "easeIn",
              delay: 0.7,
            }}
            className="rounded-2xl bg-primaryBlue p-10"
          >
            <h3 className="text-lg font-semibold leading-7 text-white">
              {t("contact.email.title")}
            </h3>
            <a href="mailto:info@bouwruimte.nl">
              <p className="font-semibold mt-3 text-sm bg-white w-fit px-4 py-2 text-primaryBlue rounded-xl hover:scale-105 transition-all">
                {t("contact.email.content")}
              </p>
            </a>
          </motion.div>

          {/* Eenmanszaak */}
          <motion.div
            initial="hidden"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            animate={controls}
            transition={{
              duration: 0.6,
              ease: "easeIn",
              delay: 0.8,
            }}
            className="rounded-2xl bg-primaryBlue p-10"
          >
            <h3 className="text-lg font-semibold leading-7 text-white">
              {t("contact.eenmanszaak.title")}
            </h3>
            <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-200">
              <div className="mt-1 flex items-baseline gap-x-2">
                <dd className="font-bold text-gray-300 text-sm">
                  {t("contact.eenmanszaak.btw.title")}:
                </dd>
                <dd>{t("contact.eenmanszaak.btw.content")}</dd>
              </div>
              <div className="mt-1 flex items-baseline gap-x-2">
                <dd className="font-bold text-gray-300 text-sm">
                  {t("contact.eenmanszaak.kvk.title")}:
                </dd>
                <dd>{t("contact.eenmanszaak.kvk.content")}</dd>
              </div>
            </dl>
          </motion.div>
        </div>

        <div
          className="absolute top-0 transform-gpu overflow-hidden right-0 blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative aspect-[1155/678] w-[36.125rem] max-w-none bg-gradient-to-tr from-secondaryBlue to-secondaryOrange opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
