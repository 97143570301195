import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";
import useMeasure from "react-use-measure";

export const SingleAcc = ({ title, children, defaultOpen = false }) => {
  const [ref, { height }] = useMeasure();
  const [open, setOpen] = useState(defaultOpen);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      onClick={() => setOpen((pv) => !pv)}
      className="border-b-[1px] border-b-slate-300 cursor-pointer"
    >
      <button className="flex w-full items-center justify-between gap-4 py-5">
        <motion.span
          variants={{
            open: {
              color: "rgba(3, 6, 23, 0)",
            },
            closed: {
              color: "rgba(3, 6, 23, 1)",
            },
          }}
          className={`bg-secondaryOrange bg-clip-text text-left xl:text-lg ${
            open ? "font-semibold" : "font-medium"
          } `}
        >
          {title}
        </motion.span>
        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "#FF8934",
            },
            closed: {
              rotate: "0deg",
              color: "#030617",
            },
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? height : "0px",
          marginBottom: open ? "30px" : "0px",
        }}
        className={`${!open && "overflow-hidden"} text-sm text-slate-600`}
      >
        <div className={`${open && "!pb-4"}`} ref={ref}>
          {children}
        </div>
      </motion.div>
    </motion.div>
  );
};
